import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";

const TableRegister = ({
  listQuantitatives,
  listDays,
  listTimes,
  setMenus,
  choiceClass,
}) => {
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const toggleDropdown = (id_day) => {
    setOpenDropdownId((prevId) => (prevId === id_day ? null : id_day));
  };
  const submitRegisterByDay = (id_day) => {
    let data = {
      // id_class: choiceClass?.value,
      // id_menu: listQuantitatives[0]?.id_menu,
      // mealsPerDay: listQuantitatives?.filter((e) => e.id_day === id_day)?.(
      //   (dayItem) => ({
      //     id_day: dayItem.id_day,
      //     meals: dayItem.menu_has_times.map((timeItem) => ({
      //       id_time: timeItem.id_time,
      //       // time_name: timeItem.time_name,
      //       foods: timeItem.foods.map((foodItem) => ({
      //         id_food: foodItem.id,
      //         name: foodItem.food_name,
      //         portions: parseInt(foodItem.quantity) || 0,
      //       })),
      //     })),
      //   })
      // ),
      // register_by: Number(localStorage?.getItem("auth_id_user")) ?? null,
    };

    // api
    //   .post(`meal-registration-create-or-update`, data, {
    //     headers: { "Content-Type": "application/json" },
    //   })
    //   .then((res) => {
    //     toast(res.data.messages || "Thêm thành công", { type: "success" });
    //   })
    //   .catch((error) => {
    //     this.setState({ errors: error.response.data.messages });
    //     toast(error.response.data.messages?.id_class || `Lỗi `, {
    //       type: "error",
    //       autoClose: 1000,
    //     });
    //   });
  };

  // const handleUpdateQuantity = (event, id_time, foodId, id_day) => {
  //   const newQuantity = parseInt(event.target.value, 10);

  //   const updatedQuantitatives = listQuantitatives.map((quantitativeItem) => {
  //     if (quantitativeItem.id_day === id_day) {
  //       return {
  //         ...quantitativeItem,
  //         menu_has_times: quantitativeItem.menu_has_times.map((timeItem) => {
  //           if (timeItem.id_time === id_time) {
  //             const foodItemToUpdate = timeItem.foods.find(
  //               (food) => food.id === foodId
  //             );
  //             const categoryId = foodItemToUpdate?.id_category;

  //             const sameCategoryFoods = timeItem.foods.filter(
  //               (food) => food.id_category === categoryId
  //             );

  //             const otherFoodsTotalQuantity = sameCategoryFoods
  //               .filter((food) => food.id !== foodId)
  //               .reduce((sum, food) => sum + food.quantity, 0);

  //             const maxQuantityForCurrentFood = quantitativeItem.totalQuantity;
  //             // Math.max(
  //             //   0,
  //             //   quantitativeItem.totalQuantity - otherFoodsTotalQuantity
  //             // );
  //             const adjustedQuantity = Math.min(
  //               newQuantity,
  //               maxQuantityForCurrentFood
  //             );

  //             return {
  //               ...timeItem,
  //               foods: timeItem.foods.map((foodItem) => {
  //                 if (foodItem.id === foodId) {
  //                   return { ...foodItem, quantity: adjustedQuantity };
  //                 } else if (foodItem.id_category === categoryId) {
  //                   const remainingQuantity =
  //                     quantitativeItem.totalQuantity - adjustedQuantity;
  //                   const distributedQuantity = Math.floor(
  //                     remainingQuantity / (sameCategoryFoods.length - 1)
  //                   );
  //                   return { ...foodItem, quantity: distributedQuantity };
  //                 }
  //                 return foodItem;
  //               }),
  //             };
  //           }
  //           return timeItem;
  //         }),
  //       };
  //     }
  //     return quantitativeItem;
  //   });

  //   setMenus(updatedQuantitatives);
  // };

  const handleUpdateQuantity = (event, id_time, foodId, id_day) => {
    const newQuantity = event.target.value;

    const updatedQuantitatives = listQuantitatives.map((quantitativeItem) => {
      if (quantitativeItem.id_day === id_day) {
        return {
          ...quantitativeItem,
          menu_has_times: quantitativeItem.menu_has_times.map((timeItem) =>
            timeItem.id_time === id_time
              ? {
                  ...timeItem,
                  foods: timeItem.foods.map((foodItem) =>
                    foodItem.id === foodId
                      ? { ...foodItem, quantity: newQuantity }
                      : foodItem
                  ),
                }
              : timeItem
          ),
        };
      }
      return quantitativeItem;
    });

    setMenus(updatedQuantitatives);
  };
  const handleUpdateTotalQuantity = (event, id_day) => {
    const newQuantity = event.target.value;

    const updatedQuantitatives = listQuantitatives.map((quantitativeItem) => {
      if (quantitativeItem.id_day === id_day) {
        return {
          ...quantitativeItem,
          totalQuantity: newQuantity,
          menu_has_times: quantitativeItem.menu_has_times.map((timeItem) => {
            return {
              ...timeItem,
              foods: timeItem.foods?.map((foodItem) => {
                const isUniqueCategory =
                  timeItem.foods?.filter(
                    (food) => food.id_category === foodItem.id_category
                  ).length === 1;

                return isUniqueCategory
                  ? {
                      ...foodItem,
                      quantity: newQuantity,
                      totalQuantity: newQuantity,
                    }
                  : { ...foodItem, totalQuantity: newQuantity };
              }),
            };
          }),
        };
      }
      return quantitativeItem;
    });

    setMenus(updatedQuantitatives);
  };

  const renderQuantitativeRows = (quantitativeTimes, categoryTimes) => {
    const result = listQuantitatives.map((listQuantitativesItem) => {
      const found = quantitativeTimes.find(
        (quantitativeTimesItem) =>
          quantitativeTimesItem.id_menu_day === listQuantitativesItem.id
      );
      return found ? found : { food: [] };
    });

    return result.map((quantitativeTimeItem) => {
      let itemRow = [];
      let rowSpanLength = 0;

      quantitativeTimeItem?.foods?.forEach((foodItem) => {
        if (foodItem.id_category === categoryTimes.id_category) {
          rowSpanLength += 1;
          itemRow.push({
            isFood: true,
            id: foodItem.id,
            name: foodItem.food_name,
            id_time: foodItem.id_time,
            id_day: foodItem.id_day,
            quantity: foodItem.quantity || 0,
            totalQuantity: foodItem.totalQuantity || 0,
            totalCategory: foodItem.totalCategory,
          });
        }
      });

      return {
        id: quantitativeTimeItem.id,
        items: itemRow,
        length: rowSpanLength !== 0 ? rowSpanLength : 1,
      };
    });
  };

  const renderCategoryRows = (categoryTimes, timeItem) => {
    return categoryTimes.map((categoryTimeItem, categoryTimeItemIndex) => {
      const arr = [];
      for (
        let i = categoryTimeItemIndex === 0 ? 1 : 0;
        i < categoryTimeItem.cateLength;
        i++
      ) {
        arr.push(
          <tr key={i}>
            {i === 0 ? (
              <th rowSpan={categoryTimeItem?.cateLength}>
                {categoryTimeItem.category_name}
              </th>
            ) : (
              ""
            )}
            {categoryTimeItem.timeQuantitative.map((rowItem) => {
              if (rowItem.items[i]?.isFood) {
                const foodName = rowItem.items[i]?.name;
                const quantity = rowItem.items[i]?.quantity;

                return (
                  <React.Fragment key={rowItem.id}>
                    <td>{foodName}</td>
                    <td>
                      <input
                        type="number"
                        value={quantity > 0 ? parseInt(quantity) : ""}
                        className="quantity-input"
                        style={{ width: "50px" }}
                        min={0}
                        disabled={rowItem.items[i].totalCategory === 1}
                        required
                        onChange={(e) =>
                          Number(e.target.value) >
                          Number(rowItem.items[i]?.totalQuantity)
                            ? toast(
                                `Số lượng phải nhỏ hơn ${rowItem.items[i]?.totalQuantity}`,
                                {
                                  type: "error",
                                  autoClose: 1000,
                                }
                              )
                            : handleUpdateQuantity(
                                e,
                                rowItem.items[i].id_time,
                                rowItem.items[i].id,
                                rowItem.items[i].id_day
                              )
                        }
                      />
                    </td>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={rowItem.id}>
                  <td>{rowItem.items[i]?.name || ""}</td>
                  <td></td>
                </React.Fragment>
              );
            })}
          </tr>
        );
      }
      return arr;
    });
  };

  return (
    <Table bordered responsive id="menu-export-excel-2">
      <thead>
        <tr className="text-center">
          <th colSpan={2}>Thời gian</th>
          {listDays.map((dayItem) => (
            <React.Fragment key={dayItem.id_day}>
              <th colSpan={2} className="text-nowrap">
                {dayItem.day_of_week?.day_name}
                <input
                  type="number"
                  // defaultValue={12}
                  value={
                    dayItem.totalQuantity > 0
                      ? parseInt(dayItem.totalQuantity)
                      : ""
                  }
                  required
                  onChange={(e) =>
                    Number(e.target.value) < 0
                      ? toast(`Số lượng phải lớn hơn 0`, {
                          type: "error",
                          autoClose: 1000,
                        })
                      : handleUpdateTotalQuantity(e, dayItem.id_day)
                  }
                  className="quantity-input ms-2"
                  style={{ width: "50px" }}
                  min={0}
                />

                {/* <>
                  <button
                    onClick={() => toggleDropdown(dayItem.id_day)}
                    className="dropdown-toggle info-icon"
                  >
                    <i className="fa fa-info-circle"></i>
                  </button>

                  {openDropdownId === dayItem.id_day && (
                    <ul className="dropdown-menu dropdown-menu-end d-flex">
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => submitRegisterByDay(dayItem.id_day)}
                        >
                          Đăng Ký
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            console.log(dayItem.id_day, ">>>>id_day duyệt")
                          }
                        >
                          Duyệt
                        </button>
                      </li>
                    </ul>
                  )}
                </> */}
              </th>
            </React.Fragment>
          ))}
        </tr>
      </thead>

      <tbody className="align-middle">
        {listTimes.map((timeItem) => {
          const quantitativeTimes = [];
          let categoryTimes = [];

          listQuantitatives?.forEach((quantitativeItem) => {
            quantitativeItem?.menu_has_times?.forEach((timeObj) => {
              if (timeObj.id_time === timeItem.id_time) {
                quantitativeTimes.push(timeObj);
                const categoryTimesToAdd =
                  timeObj.foods.length > 0
                    ? timeObj.foods.map((foodItem) => ({
                        id_category: foodItem.id_category,
                        category_name: foodItem?.category.category_name,
                      }))
                    : [{ id_category: "", category_name: "" }];
                categoryTimes = categoryTimes.concat(categoryTimesToAdd);
              }
            });
          });

          categoryTimes = [
            ...new Map(
              categoryTimes.map((item) => [item["id_category"], item])
            ).values(),
          ];

          let dataCate =
            categoryTimes.filter((ca) => ca.id_category !== "").length > 0
              ? categoryTimes.filter((ca) => ca.id_category !== "")
              : categoryTimes;

          categoryTimes = dataCate.map((categoryItem) => {
            const listItemRows = renderQuantitativeRows(
              quantitativeTimes,
              categoryItem
            );
            const maxRowSpanLength = Math.max(
              ...listItemRows.map((itemRow) => itemRow.length)
            );
            return {
              ...categoryItem,
              timeQuantitative: listItemRows.map((itemRow) => ({
                ...itemRow,
                length: maxRowSpanLength,
              })),
              cateLength: maxRowSpanLength,
            };
          });

          return (
            <React.Fragment key={timeItem.id_time}>
              <tr>
                <th
                  rowSpan={categoryTimes.reduce(
                    (total, category) => total + category.cateLength,
                    0
                  )}
                >
                  {timeItem.time_name}
                </th>

                <th rowSpan={categoryTimes[0]?.cateLength}>
                  {categoryTimes[0]?.category_name}
                </th>

                {categoryTimes[0]?.timeQuantitative?.map((rowItem) => (
                  <React.Fragment key={rowItem.id}>
                    <td>{rowItem.items[0]?.name}</td>
                    <td>
                      {rowItem.items[0]?.name ? (
                        <input
                          type="number"
                          value={
                            rowItem.items[0]?.quantity > 0
                              ? parseInt(rowItem.items[0]?.quantity)
                              : ""
                          }
                          className="quantity-input"
                          style={{ width: "50px" }}
                          min={0}
                          disabled={
                            rowItem?.items?.length === 1 ||
                            rowItem.items[0]?.totalCategory === 1
                          }
                          required
                          onChange={(e) =>
                            Number(e.target.value) >
                            Number(rowItem.items[0]?.totalQuantity)
                              ? toast(
                                  `Số lượng phải nhỏ hơn ${rowItem.items[0]?.totalQuantity}`,
                                  {
                                    type: "error",
                                    autoClose: 1000,
                                  }
                                )
                              : handleUpdateQuantity(
                                  e,

                                  rowItem.items[0].id_time,
                                  rowItem.items[0].id,
                                  rowItem.items[0]?.id_day
                                )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </React.Fragment>
                ))}
              </tr>

              {renderCategoryRows(categoryTimes, timeItem)}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableRegister;
