import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";
import swal from "sweetalert";

import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import MarketListTable from "./MarketListTable";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { startOfWeek, endOfWeek } from "date-fns";
import { dateFormatter } from "../../utils/helpers";
import { exportMartketList } from "./exportFuntions";
import Check from "../../other/Check";
import { IS_ACTIVE } from "../../../../constants";
import api from "../../../../helper/axiosInstance";
import Select from "react-select";
import { toastError } from "../../../../helper/messages";

const toSlug = (str) => {
  str = str.toLowerCase(); // Chuyển hết sang chữ thường

  // xóa dấu
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

  str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ

  str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt

  return str;
};
const CustomInput = ({ value, onClick }) => (
  <Button variant="primary" onClick={onClick}>
    <i className="fa-solid fa-calendar" />
  </Button>
);
const MarketList = () => {
  const [school, setSchool] = useState({});

  const [listVoucherMarket, setListVoucherMarket] = useState([]);
  const [voucherMarket, setVoucherMarket] = useState({
    menu_date: new Date().getTime() / 1000,
    ingredents: [],
  });
  const [isChangeVoucherMarket, setIsChangeVoucherMarket] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [keyword, setKeyWord] = useState("");

  const [suppliers, setSuplliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});

  const [voucher, setVoucher] = useState({
    menu_date: new Date().getTime() / 1000,
  });

  const [ingredients, setIngredients] = useState([]);
  const [ingredientsConstant, setIngredientsConstant] = useState([]);

  useEffect(() => {
    document.title = "Danh sách đi chợ";

    let initialWeek = datesOfWeek(new Date());

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    getMarketList(initialWeek[0]);
    getDetailschool();
    getListSuppliers();
  }, []);

  //#region  API
  const getMarketList = async (date) => {
    setIsLoading(true);
    await api
      .get(`get-kitchen-ingredient-orders`, {
        params: {
          date: date.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setVoucher(res.data.data.vouchers);
        console.log("vouchers>>", res.data.data.vouchers);
        setIngredients(res.data.data.ingredients);
        setIngredientsConstant(res.data.data.ingredients);
        // if (res.data.errCode === 0) {
        // setListDays(
        //   res.data.days.map((dayItem) => {
        //     return {
        //       id_day: dayItem.id_day,
        //       day_name: dayItem.day_name,
        //       date: new Date(dayItem.menu_date * 1000),
        //     };
        //   })
        // );

        // setSelectedDay(
        //   res.data.days.find((dayItem) => dayItem.id_day === dayId)?.id_day ||
        //     res.data.days[0].id_day
        // );

        // setListVoucherMarket(res.data.days);

        // setVoucherMarket(
        //   res.data.days.find(
        //     (listVoucherMarketItem) => listVoucherMarketItem.id_day === dayId
        //   )
        //     ? res.data.days.find(
        //         (listVoucherMarketItem) =>
        //           listVoucherMarketItem.id_day === dayId
        //       )
        //     : res.data.days[0]
        // );
        // } else {
        //   toast(res.data.message, {
        //     type: "error",
        //     autoClose: 1000,
        //   });
        // }
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListSuppliers = async () => {
    await api
      .get("/supplier-show")
      .then((res) => {
        if (res.data.errCode === 0) {
          const dataSuppliers =
            res.data?.suppliers.map((supplierItem) => {
              return {
                id: supplierItem.id,
                supplier_name: supplierItem.supplier_name,
              };
            }) || [];

          setSuplliers(dataSuppliers);
        }
      })
      .catch((error) => {});
  };

  const saveMarketList = async () => {
    setIsSendingForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("menu_date", voucher.menu_date);
    bodyFormData.append("ingredents", JSON.stringify(ingredients));

    await api({
      method: "post",
      url: "voucher-save",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendingForm(false);

        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          let initialWeek = datesOfWeek(new Date(voucher.menu_date * 1000));

          setIsChangeVoucherMarket(false);
          getMarketList(initialWeek[0], initialWeek[6], selectedDay);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const deleteVoucherMarket = (voucher) => {
    swal({
      title: "Bạn muốn xóa phiếu đi chợ",
      text: `"Ngày ${dateFormatter(new Date(voucher.menu_date * 1000))}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/voucher-destroy`, {
            params: {
              id: voucher.id_voucher,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });

              let initialWeek = datesOfWeek(new Date(voucher.menu_date * 1000));

              setIsChangeVoucherMarket(false);
              getMarketList(initialWeek[0], initialWeek[6], selectedDay);
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {});
      }
    });
  };
  // #endregion

  //#region Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    const ws = exportMartketList(ingredients);
    XLSX.utils.book_append_sheet(wb, ws, "Danh sách đi chợ");

    XLSX.writeFile(
      wb,
      `Danh sách đi chợ (${dateFormatter(
        new Date(voucher.menu_date * 1000)
      )}).xlsx`
    );
  };

  const handleXportMarketList = () => {
    swal({
      title: `Bạn muốn xuất phiếu đi chợ`,
      text: `Ngày ${dateFormatter(new Date(voucher.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const handleSaveMarketList = () => {
    swal({
      title: `Bạn muốn lưu phiếu đi chợ`,
      text: `Ngày ${dateFormatter(new Date(voucher.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Lưu"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        saveMarketList();
      }
    });
  };

  const handleCancelMarketChange = () => {
    swal({
      title: `Bạn muốn hủy thay đổi phiếu đi chợ`,
      text: `Lưu ý: hành động không thể khôi phục`,
      icon: "info",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        setIsChangeVoucherMarket(false);
        setVoucherMarket(
          listVoucherMarket.find(
            (listVoucherMarketItem) =>
              listVoucherMarketItem.id_day === selectedDay
          )
            ? listVoucherMarket.find(
                (listVoucherMarketItem) =>
                  listVoucherMarketItem.id_day === selectedDay
              )
            : listVoucherMarket[0]
        );
      }
    });
  };

  const handleSearchIngredient = (keyword, dataSuppliers = []) => {
    setKeyWord(keyword);

    const searchIngredients = ingredientsConstant?.filter((ingredientItem) => {
      const checkFilterByKeyword = toSlug(
        ingredientItem.ingredent_name.trim()
      ).includes(toSlug(keyword.trim()));

      const checkFilterBySupplierId =
        dataSuppliers.length > 0
          ? dataSuppliers.some(
              (itemSupplier) => itemSupplier.id === ingredientItem.id_supplier
            )
          : true;

      if (checkFilterByKeyword && checkFilterBySupplierId) return true;
    });

    setIngredients(searchIngredients);

    // setVoucherMarket({
    //   ...listVoucherMarket.find(
    //     (listVoucherMarketItem) => listVoucherMarketItem.id_day === selectedDay
    //   ),
    //   ingredents: searchIngredients,
    // });
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section>
        {/* <Card.Title>Chọn ngày xuất phiếu đi chợ</Card.Title> */}
        <Row>
          <Col lg={6} md={12} className="d-flex align-items-center">
            <Row>
              {listDays.map((dayItem) => {
                return (
                  <Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
                    <Form.Check
                      type="radio"
                      value={dayItem.id_day}
                      checked={selectedDay === dayItem.id_day}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDay(Number(e.target.value));
                          setKeyWord("");
                          setVoucherMarket(
                            listVoucherMarket.find(
                              (voucherMarketItem) =>
                                voucherMarketItem.id_day ===
                                Number(e.target.value)
                            )
                          );
                        }
                      }}
                      name="day"
                      className="me-2"
                      disabled={isSendingForm}
                      label={dayItem.day_name}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>

          <Col lg={3} md={6} className="mb-2">
            <Form.Control
              type="text"
              placeholder="Tìm nguyên liệu"
              value={keyword}
              onChange={(e) =>
                handleSearchIngredient(e.target.value, selectedSuppliers)
              }
              style={{ minHeight: "38px" }}
            />
          </Col>

          <Col lg={3} md={6} className="mb-2" style={{ zIndex: 1001 }}>
            <Select
              options={suppliers}
              isMulti
              placeholder={<div>Tất cả nhà cung cấp</div>}
              getOptionLabel={(option) => option.supplier_name}
              getOptionValue={(option) => option.id}
              value={selectedSuppliers}
              onChange={(choice) => {
                setSelectedSuppliers(choice);

                handleSearchIngredient(keyword, choice);
              }}
            />
          </Col>
        </Row>
      </section>
    );
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Danh sách đi chợ</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2">
                {" "}
                Danh sách đi chợ dự kiến{" "}
                {dateFormatter(new Date(voucher.menu_date * 1000))}
              </Card.Title>

              {/* Select Week */}
              <div
                className="me-2 d-flex align-items-center"
                style={{ zIndex: "1021" }}
              >
                <DatePicker
                  disabled={isSendingForm}
                  selected={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  startDate={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  endDate={
                    listDays?.length > 0
                      ? new Date(listDays[listDays.length - 1]?.date)
                      : new Date()
                  }
                  onChange={(date) => {
                    const startDateGMT = startOfWeek(date, { weekStartsOn: 1 });
                    const endDateGMT = startOfWeek(
                      endOfWeek(date, { weekStartsOn: 1 })
                    );
                    getMarketList(
                      new Date(startDateGMT),
                      new Date(endDateGMT),
                      selectedDay
                    );
                    datesOfWeek(startDateGMT);

                    setKeyWord("");
                  }}
                  showWeekNumbers
                  dateFormat="yyyy-MM-dd"
                  calendarClassName="custom-calendar"
                  customInput={<CustomInput />}
                  locale={vi}
                />
              </div>
            </div>

            <div className="d-flex">
              <Button
                size="sm"
                variant="success"
                onClick={() => handleXportMarketList()}
                className="me-2"
                disabled={isLoading || isSendingForm}
              >
                <i className="fa-solid fa-file-export" /> Xuất file
              </Button>
              {voucher.is_voucher_saved === IS_ACTIVE.TRUE &&
              new Check().permission([118]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => deleteVoucherMarket(voucher)}
                  className="me-2"
                  disabled={isLoading || isSendingForm}
                >
                  <i className="fa-solid fa-times" /> Hủy phiếu đi chợ
                </Button>
              ) : (
                <></>
              )}

              {voucher.is_voucher_saved === IS_ACTIVE.FALSE &&
              new Check().permission([117]) ? (
                <>
                  <Button
                    variant={isChangeVoucherMarket ? "danger" : "success"}
                    size="sm"
                    onClick={() => {
                      if (isChangeVoucherMarket) {
                        handleCancelMarketChange();
                      } else {
                        setIsChangeVoucherMarket(true);
                      }
                    }}
                    disabled={isLoading || isSendingForm}
                  >
                    {isChangeVoucherMarket ? (
                      <>
                        <i className="fa-solid fa-times" /> Hủy thay đổi
                      </>
                    ) : (
                      <>
                        <i className="fa-solid fa-exchange" /> Thay đổi phiếu đi
                        chợ
                      </>
                    )}
                  </Button>

                  {isChangeVoucherMarket ? (
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleSaveMarketList(true)}
                      className="ms-2"
                      disabled={isLoading || isSendingForm}
                    >
                      <i className="fa-solid fa-check" /> Lưu phiếu đi chợ
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
              {RenderOption()}

              <MarketListTable
                voucher={voucher}
                ingredients={ingredients}
                setIngredients={setIngredients}
                school={school}
                suppliers={suppliers}
                isChangeVoucherMarket={isChangeVoucherMarket}
              />
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MarketList;
