import React, { Component } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { Button, Row, Col, Card, Container, Breadcrumb } from "react-bootstrap";
import DataTables from "../../admin/datatable/DataTables";
import Check from "../../admin/other/Check";
import UnitAdd from "./UnitAdd";
import UnitEdit from "./UnitEdit";
import api from "../../../helper/axiosInstance";

class UnitIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      units: [],
      addModalShow: false,

      selecttedUnitId: 0,
      editModalShow: false,
    };
  }

  componentDidMount() {
    document.title = "Đơn vị tính";
    this.getListUnits();
  }

  // API
  getListUnits = async () => {
    await api
      .get(`/unit-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({
            units: res.data.units,
          });
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  deleteUnit = (unitItem) => {
    swal({
      title: "Bạn muốn xóa đơn vị?",
      text: `"${unitItem.unit_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .delete(`/unit-destroy?`, {
            params: {
              id: unitItem.id,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });
              this.getListUnits();
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((err) => {});
      }
    });
  };
  // API

  // Logic
  handleShowAddModal = () => {
    this.setState({ addModalShow: true });
  };
  handleCloseAddModal = (isReload) => {
    this.setState({
      addModalShow: false,
    });

    if (isReload) {
      this.getListUnits();
    }
  };

  handleShowEditModal = (id) => {
    this.setState({ editModalShow: true, selecttedUnitId: id });
  };
  handleCloseEditModal = (isReload) => {
    this.setState({
      editModalShow: false,
      selecttedUnitId: 0,
    });

    if (isReload) {
      this.getListUnits();
    }
  };
  // Logic

  render() {
    const columns = [
      {
        name: "Đơn vị",
        selector: (row) => row.unit_name,
        sortable: true,
      },
      {
        name: "Phương thức",
        center: true,
        cell: (row) => (
          <>
            {new Check().permission(["67"], "or") ? (
              <Button
                size="sm"
                variant="warning me-2"
                type="button"
                onClick={() => this.handleShowEditModal(row.id)}
                title="Chi tiết đơn vị"
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
            ) : null}
            {new Check().permission(["70"], "or") ? (
              <Button
                size="sm"
                variant="danger"
                type="button"
                onClick={() => this.deleteUnit(row)}
                title="Xóa đơn vị"
              >
                <i className="fas fa-trash"></i>
              </Button>
            ) : null}
          </>
        ),
      },
    ];

    return (
      <Container fluid className="px-3">
        <Breadcrumb className="mb-2 mt-2">
          <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
          <Breadcrumb.Item active>Đơn vị</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col xs={12} md={5}>
            <Card className="mb-4">
              <Card.Header>
                {new Check().permission(["68"], "or") ? (
                  <Button
                    type="button"
                    variant="success"
                    size="sm"
                    style={{ float: "right" }}
                    onClick={() => this.handleShowAddModal()}
                  >
                    <i className="fa-solid fa-plus"></i> Thêm đơn vị
                  </Button>
                ) : null}
                <Card.Title>
                  <i className="fas fa-list me-1"></i> Danh sách đơn vị
                </Card.Title>
              </Card.Header>

              <Card.Body>
                <DataTables data={this.state.units} columns={columns} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {this.state.addModalShow ? (
          <UnitAdd
            show={this.state.addModalShow}
            onHide={this.handleCloseAddModal}
          />
        ) : null}

        {this.state.editModalShow ? (
          <UnitEdit
            show={this.state.editModalShow}
            onHide={this.handleCloseEditModal}
            unitId={this.state.selecttedUnitId}
          />
        ) : null}
      </Container>
    );
  }
}

export default UnitIndex;
