import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

// import DataTables from "../../../admin/datatable/DataTables";
import swal from "sweetalert";
import Check from "../../other/Check";
import SupplierFormModal from "../../Supplier/SupplierFormModal";
import api from "../../../../helper/axiosInstance";
const BankFoodAdd = ({ show, onHide, foods }) => {
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedFoods, setSelectedFoods] = useState(foods);
  const [errorMessage, setErrorMessage] = useState({});
  const [modalSuppliershow, setModalSuppliershow] = useState(false);
  useEffect(() => {
    getListSuppliers();
  }, []);

  // API
  const getListSuppliers = async () => {
    await api
      .post(`/viewSupplier`)
      .then((res) => {
        if (res.data.status === true) {
          setSupplierOptions(res.data.data);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const addBankFoodtoList = async () => {
    setErrorMessage({});

    if (!selectedSupplier) {
      toast("Vui lòng chọn nhà cung cấp", {
        type: "error",
        autoClose: 1000,
      });

      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append("id_supplier", selectedSupplier.id);
    bodyFormData.append("ing_price_expect", 10000);
    bodyFormData.append(
      "food_code_arr",
      JSON.stringify(
        selectedFoods.map((foodItem) => {
          return foodItem.food_code;
        })
      )
    );

    await api({
      method: "post",
      url: "food-create-from-bank",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode === 0) {
          toast("Thêm thành công", {
            type: "success",
            autoClose: 1000,
          });

          onHide(true);
        } else {
          setErrorMessage(res.data.message);

          toast("Thêm thất bại, vui lòng kiểm tra lại thông tin món", {
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {});
  };
  // API

  // Render
  // const foodColumns = [
  // 	{
  // 		name: "Mã món ăn",
  // 		selector: (row) => row.food_code,
  // 		sortable: true,
  // 	},
  // 	{
  // 		name: "Tên món ăn",
  // 		selector: (row) => row.food_name,
  // 		sortable: true,
  // 	},
  // 	{
  // 		name: "Danh mục",
  // 		selector: (row) => row.category_name,
  // 		sortable: true,
  // 	},
  // 	{
  // 		name: "Đơn vị tính",
  // 		selector: (row) => row.unit_name,
  // 		sortable: true,
  // 	},
  // 	{
  // 		name: "Phương thức",
  // 		center: true,
  // 		selector: (row) => (
  // 			<Button
  // 				size="sm"
  // 				variant="danger"
  // 				type="button"
  // 				onClick={() => {
  // 					swal({
  // 						title: "Bạn muốn bỏ món ăn này?",
  // 						text: `"${row.food_name}"`,
  // 						icon: "error",
  // 						buttons: ["Đóng", "Bỏ"],
  // 						dangerMode: true,
  // 					}).then(async (ok) => {
  // 						if (ok) {
  // 							setSelectedFoods(
  // 								selectedFoods.filter(
  // 									(food) =>
  // 										food.food_code !== row.food_code,
  // 								),
  // 							);

  // 							toast("Đã bỏ món ăn", {
  // 								type: "success",
  // 								autoClose: 1000,
  // 							});
  // 						}
  // 					});
  // 				}}
  // 			>
  // 				<i className="fas fa-trash"></i>
  // 			</Button>
  // 		),
  // 		sortable: true,
  // 	},
  // ];

  // const ingredientColumns = [
  // 	{
  // 		name: "Mã nguyên liệu",
  // 		selector: (row) => row.ingredent_code,
  // 		sortable: true,
  // 	},
  // 	{
  // 		name: "Tên nguyên liệu",
  // 		selector: (row) => row.ingredent_name,
  // 		sortable: true,
  // 	},
  // 	{
  // 		name: "Định lượng",
  // 		selector: (row) =>
  // 			row.groups.map((group) => {
  // 				return (
  // 					<div key={group.id}>
  // 						{group.group_name}: {group.quantity}{" "}
  // 						{group.unit_cook_name}
  // 					</div>
  // 				);
  // 			}),
  // 		sortable: true,
  // 	},
  // ];

  // const IngredientTable = ({ data }) => {
  // 	if (data.ingredents.length === 0) {
  // 		return <i className="text-muted ms-5">Không có dữ liệu!</i>;
  // 	}

  // 	return (
  // 		<DataTables
  // 			className="ms-5"
  // 			data={data.ingredents}
  // 			columns={ingredientColumns}
  // 		/>
  // 	);
  // };

  return (
    <>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        show={show}
        onHide={() => onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-solid fa-bowl-rice me-2"></i>
            Thêm món ăn
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {new Check().permission(["24"]) ? (
            <Form.Group className="text-end">
              <Link
                className="fst-italic"
                onClick={() => setModalSuppliershow(true)}
              >
                +Thêm nhà cung cấp
              </Link>
            </Form.Group>
          ) : null}
          <Select
            options={supplierOptions}
            className="text-start"
            placeholder={<>Chọn nhà cung cấp</>}
            getOptionLabel={(option) => option.supplier_name}
            getOptionValue={(option) => option.id}
            value={selectedSupplier}
            onChange={(choice) => {
              setSelectedSupplier(choice);
            }}
            menuPortalTarget={document.querySelector("body")}
            styles={{
              control: (base) => ({ ...base, marginBottom: "1rem" }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />

          {/* <DataTables
					data={selectedFoods}
					columns={foodColumns}
					expandableRows
					expandableRowsComponent={IngredientTable}
				/> */}
          <div style={{ overflow: "auto" }}>
            <Table bordered hover>
              <thead>
                <tr className="align-middle text-center">
                  <th>STT</th>
                  <th>Mã món ăn</th>
                  <th style={{ minWidth: "150px" }}>Tên món ăn</th>
                  <th>Danh mục</th>
                  <th>Đơn vị tính</th>
                  <th>Phương thức</th>
                </tr>
              </thead>
              <tbody>
                {selectedFoods.map((foodItem, index) => {
                  return (
                    <React.Fragment key={foodItem.food_code}>
                      <tr className="align-middle">
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{foodItem.food_code}</td>
                        <td>{foodItem.food_name}</td>
                        <td>{foodItem.category_name}</td>
                        <td>{foodItem.unit_name}</td>
                        <td className="text-center">
                          <Button
                            size="sm"
                            variant="danger"
                            type="button"
                            onClick={() => {
                              swal({
                                title: "Bạn muốn bỏ món ăn này?",
                                text: `"${foodItem.food_name}"`,
                                icon: "error",
                                buttons: ["Đóng", "Bỏ"],
                                dangerMode: true,
                              })
                                .then(async (ok) => {
                                  if (ok) {
                                    setSelectedFoods(
                                      selectedFoods.filter(
                                        (food) =>
                                          foodItem.food_code !== food.food_code
                                      )
                                    );

                                    toast("Đã bỏ món ăn", {
                                      type: "success",
                                      autoClose: 1000,
                                    });
                                  }
                                })
                                .catch((err) => {});
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        </td>
                      </tr>

                      {errorMessage[`food_code_arr.${index}`] && (
                        <tr
                          style={{
                            fontSize: 13,
                            color: "red",
                            marginTop: 6,
                          }}
                        >
                          <td colSpan={6}>
                            {errorMessage[`food_code_arr.${index}`]}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => onHide()}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>

          <Button
            size="sm"
            type="submit"
            variant="success"
            onClick={() => addBankFoodtoList()}
          >
            <i className="fa-solid fa-check"></i> Lưu
          </Button>
        </Modal.Footer>
      </Modal>
      {modalSuppliershow ? (
        <SupplierFormModal
          show={modalSuppliershow}
          onHide={() => setModalSuppliershow(false)}
          modal={{
            title: "Thêm nhà cung cấp",
            button: (
              <>
                <i className="fa-solid fa-check" /> Lưu
              </>
            ),
          }}
          data={{
            id: "",
            supplier_name: "",
            supplier_phone: "",
            supplier_address: "",
            supplier_author: "",
            supplier_shipper: "",
          }}
          submit={() => {
            setModalSuppliershow(false);
            getListSuppliers();
          }}
        />
      ) : null}
    </>
  );
};

export default BankFoodAdd;
