import { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { toast } from "react-toastify";
import api from "../../../../helper/axiosInstance";

const MenuFoodsModal = ({
  show,
  onHide,
  foodModal,
  setFoodModal,
  foodOptions,
  classGroups,
  selectedClassGroup,
}) => {
  const [categories, setCategories] = useState([]);
  const [choiceCategory, setChoiceCategory] = useState({});
  const [foodChoice, setFoodChoice] = useState({
    id: 0,
    food_name: "Chọn món",
    id_category: "",
    category_name: "",
  });

  useEffect(() => {
    getListCategories();
  }, []);

  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          let arr = [];
          arr.push({ id: 0, category_name: "Tất cả" });
          res.data.categories?.map((item) => arr.push(item));
          setCategories(arr);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const foods = foodModal.foods;

  return (
    <Modal show={show} size="lg" onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {foodModal.day_name} - Buổi {foodModal.time_name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Món ăn</th>
              {/* {classGroups?.map((groupItem) => {
                return (
                  <th key={`groupItem0_${groupItem.id}`}>
                    {groupItem.group_name}
                  </th>
                );
              })} */}
              <th>Danh mục</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {foods.map((foodItem, keyFood) => {
              if (
                foodItem?.groups.some(
                  (group) => group.id === selectedClassGroup.id
                )
              )
                return (
                  <tr key={`food-key-${keyFood}`}>
                    <td>{foodItem.food_name}</td>
                    <td>{foodItem.category_name}</td>
                    <td>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => {
                          const groups = foodModal.foods[keyFood]?.groups;
                          const keyGroup = groups.findIndex(
                            (group) => group.id == selectedClassGroup.id
                          );

                          if (groups.length > 1)
                            delete foodModal.foods[keyFood].groups[keyGroup];
                          else delete foodModal.foods[keyFood];

                          setFoodModal({
                            ...foodModal,
                          });
                        }}
                        title="Xóa món"
                      >
                        <i className="fa-solid fa-times" />
                      </Button>
                    </td>
                  </tr>
                );
            })}
          </tbody>
        </Table>

        <Row>
          <Col className="mb-2">
            <InputGroup>
              <InputGroup.Text>
                <i className="fa-solid fa-filter" />
                &nbsp;Danh mục
              </InputGroup.Text>

              <Select
                options={categories}
                getOptionLabel={(option) => option.category_name}
                getOptionValue={(option) => option.id}
                placeholder="Tất cả"
                onChange={(choice) => {
                  setChoiceCategory(choice);
                  setFoodChoice({ id: 0, food_name: "Chọn món" });
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minWidth: "235px",
                  }),
                }}
              />
            </InputGroup>
          </Col>

          <Col>
            <InputGroup>
              <InputGroup.Text>
                <i className="fa-solid fa-search" />
                &nbsp;Món ăn
              </InputGroup.Text>
              <Select
                options={
                  choiceCategory.id
                    ? foodOptions
                        ?.filter((e) => e.id_category == choiceCategory.id)
                        ?.filter(
                          (foodItem) =>
                            !foodModal.foods.some(
                              (food) => food.id === foodItem.id
                            )
                        )
                    : foodOptions
                  // foodOptions?.filter(
                  //   (foodItem) =>
                  //     !foodModal.foods.some(
                  //       (food) => food.id === foodItem.id
                  //     )
                  // )
                }
                getOptionLabel={(option) => option.food_name}
                getOptionValue={(option) => option.id}
                value={foodChoice}
                placeholder="Nhập tên món ăn"
                onChange={(choice) => {
                  let foods = foodModal.foods;

                  let groups = [];

                  setFoodChoice(choice);

                  // classGroups.map((group) => {
                  //   groups.push({
                  //     id: group.id,
                  //     group_name: group.group_name,
                  //     more_meal: "",
                  //     ingredents: [],
                  //   });
                  //   return group;
                  // });

                  console.log("foods>>", foods);

                  groups.push({
                    id: selectedClassGroup.id,
                    group_name: selectedClassGroup.group_name,
                    more_meal: "",
                    ingredents: [],
                  });

                  if (
                    !foods.some((food) => Number(food.id) === Number(choice.id))
                  ) {
                    foods.push({
                      id: choice.id,
                      food_name: choice.food_name,
                      id_category: choice.id_category,
                      category_name: choice.category_name,
                      priority: 1,
                      groups: groups,
                    });

                    foodModal.foods = foods;

                    setFoodModal({ ...foodModal });
                  } else if (
                    !foods.some(
                      (food) =>
                        Number(food.id) === Number(choice.id) &&
                        food?.groups.some(
                          (group) => group.id === selectedClassGroup.id
                        )
                    )
                  ) {
                    const indexFood = foods.findIndex(
                      (food) => Number(food.id) === Number(choice.id)
                    );

                    const groupsOld = foods.find(
                      (food) => Number(food.id) === Number(choice.id)
                    )?.groups;

                    foods[indexFood] = {
                      id: choice.id,
                      food_name: choice.food_name,
                      id_category: choice.id_category,
                      category_name: choice.category_name,
                      priority: 1,
                      groups: [...groups, ...groupsOld],
                    };
                  } else {
                    toast("Bạn đã chọn món ăn này rồi", {
                      type: "error",
                      autoClose: 1000,
                    });
                  }
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minWidth: "250px",
                  }),
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times" /> Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MenuFoodsModal;
