import React, { Component } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import Check from "../other/Check";
import Select from "react-select";
import api from "../../../helper/axiosInstance";

class GroupCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupname: "",
      recommended_from: this.props.PT == "CN" ? this.props.recommended_from : 0,
      recommended_to:
        this.props.PT == "TM" || this.props.PT == ""
          ? 0
          : this.props.recommended_to,
      recommended: false,
      enegry_ratio:
        this.props.PT == "TM" || this.props.PT == ""
          ? 0
          : this.props.enegry_ratio,
      idAgeGroup:
        this.props.PT == "TM" || this.props.PT == ""
          ? 0
          : this.props.id_groupAge,
      choiceAge: {},
      ages: [],
      err: {},
    };
  }
  componentDidMount() {
    this.getAgeGroups();
  }
  getAgeGroups = () => {
    let arr = [];
    api.get(`/age-group-show`).then((res) => {
      if (res.data.errCode === 0) {
        res.data.ageGroups?.map((e) => {
          arr?.push({
            value: e.id,
            label: e.age_group_name,
            enegry_ratio: e.enegry_ratio,
            default_energy_max: e.default_energy_max,
            default_energy_min: e.default_energy_min,
          });
        });

        this.setState({
          ages: arr,
          choiceAge: arr?.filter(
            (item) => item.value == this.state.idAgeGroup
          )[0],
        });
      }
    });
  };
  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  creategroup() {
    let data = {
      id_age_group: this.state.choiceAge?.value || 0,
      group_name: this.state.groupname,
      recommended_from: Number(this.state.recommended_from),
      recommended_to: Number(this.state.recommended_to),
      enegry_ratio: this.state.enegry_ratio,
    };
    api({
      method: "post",
      url: "group-create",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode == 0) {
          this.setState({
            recommended_from: 0,
            recommended_to: 0,
            id_age_group: 0,
            group_name: "",
            recommended_from: 0,
            recommended_to: 0,
            enegry_ratio: 0,
          });
          this.props.close();
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
        } else {
          this.setState({ err: res.data.message });
          // toast(`${res.data.data.mess}`, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {});
  }

  updategroup() {
    let data = {
      id: this.props.idgroup,
      id_age_group: this.state.choiceAge?.value,
      group_name:
        this.state.groupname == ""
          ? this.props.groupname
          : this.state.groupname,
      recommended_from: Number(this.state.recommended_from),
      recommended_to: Number(this.state.recommended_to),
      enegry_ratio: this.state.enegry_ratio,
    };
    api({
      method: "put",
      url: "group-update",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode == 0) {
          this.setState({
            recommended_from: 0,
            recommended_to: 0,
            id_age_group: 0,
            group_name: "",
            recommended_from: 0,
            recommended_to: 0,
            enegry_ratio: 0,
          });
          this.props.close();
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });
        } else {
          this.setState({ err: res.data.message });
        }
      })
      .catch((error) => {});
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h1 className="modal-title fs-5" id="staticBackdropLabel">
            {this.props.PT === "TM" || this.props.PT === ""
              ? "Thêm nhóm học sinh"
              : "Chi tiết nhóm học sinh"}
          </h1>
        </Modal.Header>
        <Modal.Body>
          {/* {console.log(this.props)} */}
          <Form>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                placeholder="Tên nhóm học sinh"
                defaultValue={
                  this.props.PT == "TM" || this.props.PT == ""
                    ? ""
                    : this.props.groupname
                }
                onChange={(e) => {
                  if (this.props.PT == "TM" || this.props.PT == "") {
                    this.setState({ groupname: e.target.value });
                  } else {
                    this.setState({ groupname: e.target.value });
                  }
                  delete this.state.err["group_name"];
                }}
              />
              <Form.Label>
                Tên nhóm học sinh<i className="text-danger">*</i>
              </Form.Label>
              <div className="text-form-err">{this.state.err?.group_name}</div>
            </Form.Floating>

            <Row className="mb-2">
              <Col style={{ zIndex: 3 }}>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "50px",
                    }),
                  }}
                  required
                  options={this.state.ages}
                  placeholder={
                    <>
                      Chọn nhóm tuổi<i className="text-danger">*</i>{" "}
                    </>
                  }
                  name="id_age_group"
                  value={this.state.choiceAge || "Chọn nhóm tuổi"}
                  onChange={(choice) => {
                    this.setState({
                      recommended_from: choice.default_energy_min,
                      choiceAge: choice,
                      recommended_to: choice.default_energy_max,
                      enegry_ratio: choice.enegry_ratio,
                    });
                    delete this.state.err["id_age_group"];
                    delete this.state.err["recommended_from"];
                    delete this.state.err["recommended_to"];
                    delete this.state.err["enegry_ratio"];
                  }}
                />
                <div className="text-form-err">
                  {this.state.err?.id_age_group ? " Không được để trống" : ""}
                </div>
              </Col>
            </Row>
            <p className="text-center">
              {" "}
              Nhu cầu khuyến nghị năng lượng (Kcal)
            </p>
            <Row>
              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    placeholder="Từ"
                    thousandSeparator={true}
                    suffix=" Kcal"
                    value={this.state.recommended_from}
                    onValueChange={(values) => {
                      this.setState({
                        recommended_from: values.value,
                      });
                      delete this.state.err["recommended_from"];
                    }}
                  />
                  <Form.Label>
                    Từ<i className="text-danger"></i> (Kcal)
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                  <div className="text-form-err">
                    {this.state.err?.recommended_from}
                  </div>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    placeholder="Đến"
                    value={this.state.recommended_to}
                    suffix=" Kcal"
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      this.setState({
                        recommended_to: values.value,
                      });
                      delete this.state.err["recommended_to"];
                    }}
                  />
                  <Form.Label>
                    Đến<i className="text-danger"></i> (Kcal)
                  </Form.Label>
                  <div className="text-form-err">
                    {this.state.err?.recommended_to}
                  </div>

                  <div className="invalid-feedback"></div>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    placeholder="Đến"
                    value={this.state.enegry_ratio}
                    suffix=" %"
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      this.setState({
                        enegry_ratio: values.value,
                      });
                      delete this.state.err["enegry_ratio"];
                    }}
                  />
                  <Form.Label>
                    Đạt<i className="text-danger"></i> (%)
                  </Form.Label>
                  <div className="invalid-feedback"></div>
                  <div className="text-form-err">
                    {this.state.err?.enegry_ratio}
                  </div>
                </Form.Floating>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={this.props.close}>
            <i className="fa-solid fa-times"></i> Đóng
          </Button>
          {new Check().permission(["9"]) ? (
            <Button
              size="sm"
              type="submit"
              variant="success"
              onClick={() => {
                if (this.props.PT === "TM" || this.props.PT === "") {
                  this.creategroup();
                } else {
                  this.updategroup();
                }
              }}
            >
              <i className="fa-solid fa-check"></i>{" "}
              {this.props.PT === "TM" || this.props.PT === ""
                ? "Lưu"
                : " Cập nhật"}
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default GroupCreate;
