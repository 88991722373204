import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";

class StoreCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: {
        id: "",
        ingredent_price: "",
        supplier: {
          id_supplier: "",
          ingredent_price: "",
        },
        expriry_date: new Date() / 1000,
        rec_date: new Date() / 1000,

        amount: "",
      },
      inputSuppiler: {
        id_supplier: "",
        ingredent_price: "",
      },
      suppliers: [],
    };
  }

  componentDidMount() {}

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
  };

  handleInputsupplier = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput.supplier[field] = value;
    this.setState({ dataInput: dataInput });
  };
  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  createinventory = () => {
    let supplier = this.state.inputSuppiler;

    api
      .post(
        `/createInventory?id_ingredent=${
          this.props.propsdata.id
        }&supplier=[${JSON.stringify(supplier)}]&expriry_date=${
          this.state.dataInput.expriry_date != null
            ? this.state.dataInput.expriry_date
            : new Date() / 1000
        }&amount=${
          this.state.dataInput.amount === ""
            ? Number(this.props.propsdata.amount)
            : Number(this.state.dataInput.amount)
        }`
      )
      .then((res) => {
        if (res.data.status === true) {
          this.props.getStoreData(this.props.tabkey);
          toast(res.data.data.mess, { type: "success", autoClose: 1000 });
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {});
  };

  getaddliststore = (e) => {
    e.preventDefault();
    let ing = [
      {
        id_ingredent: this.props.propsdata.id,
        id_supplier: this.state.inputSuppiler?.id_supplier || 0,
        id_unit_market: this.props.propsdata?.id_unit || 0,
        ing_quantity:
          this.state.dataInput.amount === ""
            ? Number(this.props.propsdata.amount)
            : Number(this.state.dataInput.amount),
        ing_price: this.state.dataInput?.ingredent_price,
        ing_mfg_date: parseInt(new Date() / 1000),
        ing_exp_date: parseInt(
          this.state.dataInput.expriry_date != null
            ? this.state.dataInput.expriry_date
            : new Date() / 1000
        ),
        ingredent_type: Number(this.props.propsdata?.tabkey || 1) + 1,
        note: "",
      },
    ];

    let data = {
      rec_date: parseInt(
        this.state.dataInput.rec_date != null
          ? this.state.dataInput.rec_date
          : new Date() / 1000
      ),
      rec_shipper: "",
      invoice_code: "",
      invoice_date: parseInt(new Date() / 1000),
      rec_notes: "",
      ingredents: JSON.stringify(ing),
    };

    if (
      this.state.dataInput.amount === ""
        ? Number(this.props.propsdata.amount)
        : Number(this.state.dataInput.amount) <= 0
    ) {
      toast("Số lượng nhập phải lớn hơn bằng 0", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }

    swal({
      title: `Bạn chắc muốn Nhập kho?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api({
            method: "post",
            url: "store-import",
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => {
              if (res.data.errCode === 0) {
                this.props.getStoreData(this.props.tabkey);
                toast(res.data.message, { type: "success", autoClose: 1000 });
              } else {
                toast(res.data.message, { type: "error", autoClose: 1000 });
              }
            })
            .catch((err) => {
              toast("Hệ thống xảy ra lỗi", {
                type: "error",
                autoClose: 2000,
              });
            });
        }
      })
      .catch((error) => {});
  };

  dataSuppliers() {
    if (this.props.suppliers.length > 0) {
      return this.props.suppliers.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.supplier_name}
          </option>
        );
      });
    }
  }

  render() {
    return (
      <Modal
        size="md"
        show={this.props.show}
        onHide={this.props.onHide}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nhập kho</Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.getaddliststore}>
          <Modal.Body>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                defaultValue={this.props.propsdata.ingredent_name}
                placeholder="Tên nguyên liệu"
                disabled
                required
              />
              <Form.Label>
                Tên nguyên liệu<i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>

            <Row>
              <p className="text-center">
                Chọn nhà cung cấp và nhập giá cho lô hàng
              </p>
              <Col>
                <Form.Floating className="mb-3">
                  <Form.Select
                    onChange={(choice) => {
                      let dataInput = { ...this.state.inputSuppiler };
                      dataInput.id_supplier = choice.target.value;
                      this.setState({ inputSuppiler: dataInput });
                    }}
                    name="id_supplier"
                    required
                  >
                    <option value="">--Chọn--</option>
                    {this.dataSuppliers()}
                  </Form.Select>
                  <Form.Label>
                    Nhà cung cấp<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Floating>
                  <CurrencyFormat
                    className="form-control"
                    name="ingredent_price"
                    allowNegative={false}
                    thousandSeparator={true}
                    placeholder="Giá nhập"
                    required
                    onValueChange={(values) => {
                      let dataInput = { ...this.state.dataInput };
                      dataInput.ingredent_price = values.value;
                      this.setState({ dataInput: dataInput });
                    }}
                  />
                  <Form.Label>
                    Giá<i className="text-danger">*</i> (VND)
                  </Form.Label>
                </Form.Floating>
              </Col>

              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control"
                    allowNegative={false}
                    name="amount"
                    placeholder="số lượng"
                    thousandSeparator={true}
                    value={this.state.dataInput.amount}
                    required
                    onValueChange={(values) => {
                      let dataInput = { ...this.state.dataInput };
                      dataInput.amount = values.value;
                      this.setState({ dataInput: dataInput });
                    }}
                  />
                  <Form.Label>
                    Số lượng<i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  {Number(this.props.tabkey) === 1 ? (
                    <>
                      <Form.Label>
                        Hạn sử dụng<i className="text-danger">*</i>
                      </Form.Label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          value={
                            this.state.dataInput.expriry_date
                              ? this.state.dataInput.expriry_date * 1000
                              : null
                          }
                          required
                          onChange={(newvalue) => {
                            let dataInput = this.state.dataInput;
                            dataInput.expriry_date =
                              newvalue.toDate().valueOf() / 1000;
                            this.setState({
                              dataInput: dataInput,
                            });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Ngày nhập kho<i className="text-danger"></i>
                  </Form.Label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      value={
                        this.state.dataInput.rec_date
                          ? this.state.dataInput.rec_date * 1000
                          : null
                      }
                      required
                      onChange={(newvalue) => {
                        let dataInput = this.state.dataInput;
                        dataInput.rec_date = newvalue.toDate().valueOf() / 1000;
                        this.setState({
                          dataInput: dataInput,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={this.props.onHide}>
              <i className="fa-solid fa-times"></i> Đóng
            </Button>

            <Button size="sm" type="submit" variant="success">
              <i className="fa-solid fa-check"></i> Lưu
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default StoreCreate;
