import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Modal,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { startOfWeek, endOfWeek } from "date-fns";

import swal from "sweetalert";
import Select from "react-select";
import FoodQuantitativeTable from "./portionCalculateComponent/FoodQuantitativeTable";

import {
  exportMarketBill,
  exportMarketBillAll,
  exportMarketBillAllbyGroup,
  exportNutritionResult,
  exportNutritionSetupResult,
} from "./excelReport/exportFunctions";
import { groupArrayByField, dateFormatter } from "../../utils/helpers";
import Check from "../../other/Check";
import zIndex from "@mui/material/styles/zIndex";
import api from "../../../../helper/axiosInstance";

const PortionCalculate = () => {
  const [menu, setMenu] = useState({});

  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [quantitative, setQuantitative] = useState({
    menu_date: new Date().getTime() / 1000,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [isChangeMarket, setIsChangeMarket] = useState(false);
  const [isChangeFood, setIsChangeFood] = useState(false);
  const [classGroups, setClassGroups] = useState([]);
  const [selectedClassGroup, setSelectedClassGroups] = useState({});
  const [suppliers, setSuplliers] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});
  const [dataDays, setDataDays] = useState([]);
  const [optionModal, setOptionModal] = useState(false);
  const [optionGroup, setOptionGroup] = useState([]);
  const [roundUp, setRoundUp] = useState(false);

  useEffect(() => {
    document.title = "Tính khẩu phần ăn";

    let initialWeek = datesOfWeek(new Date());

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    getListQuantitatives(initialWeek[0], initialWeek[6], todayIs);
    getListClassGroups();
    getListSuppliers();
    getListIngredients();
  }, []);

  const onHideOptionModal = () => {
    setOptionModal(false);
    setOptionGroup([]);
  };

  //#region  API
  const getListQuantitatives = async (startDate, endDate, dayId) => {
    setIsLoading(true);

    setMenu({});
    setListQuantitatives([]);
    setListDays([]);
    setQuantitative({ menu_date: startDate / 1000 });

    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setMenu(res.data.menu);

          const resDays = res.data.days;

          if (resDays.length > 0) {
            setListDays(
              resDays.map((dayItem) => {
                return {
                  id_day: dayItem.id_day,
                  day_name: dayItem.day_name,
                  date: new Date(dayItem.menu_date * 1000),
                };
              })
            );

            const day = resDays.filter((item) => item.id_day === dayId);
            const idDayselected =
              day.length > 0 ? day[0].menu_date : resDays[0]["menu_date"];
            getListSettings(resDays, dayId, idDayselected);
            setDataDays(resDays);
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListSettings = async (
    listQuantitatives,
    dayId,
    setting_date = parseInt(quantitative.menu_date)
  ) => {
    setIsLoading(true);

    await api
      .get(`/setting-show?setting_date=${setting_date}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          try {
            const dataGroups = res.data.setting.groups;

            const newListQuantitatives = listQuantitatives.map(
              (quantitativeItem) => {
                return {
                  ...quantitativeItem,
                  costPerDays: quantitativeItem.costPerDays.map(
                    (costPerDay) => {
                      const daySetting = dataGroups.find(
                        (group) => group.id === costPerDay.id_group
                      );

                      return {
                        ...costPerDay,
                        meal_quantity: 1,
                        start_balance: Math.round(
                          Number(costPerDay.start_balance)
                        ),
                        fee_other: daySetting
                          ? Number(daySetting.fee_other)
                          : 0,
                        fee_meal: daySetting ? Number(daySetting.fee_meal) : 0,
                        price_meal: daySetting
                          ? Number(daySetting.price_meal)
                          : 0,
                      };
                    }
                  ),
                  times: quantitativeItem.times?.map((timeItem) => {
                    return {
                      ...timeItem,
                      foods: timeItem.foods?.map((foodItem) => {
                        return {
                          ...foodItem,
                          groups: foodItem.groups?.map((groupItem) => {
                            return {
                              ...groupItem,
                              ingredents: groupItem.ingredents?.map(
                                (ingredientItem) => {
                                  return {
                                    ...ingredientItem,
                                    tmp_ing_quantity:
                                      ingredientItem.ing_quantity,
                                    tmp_id_supplier: ingredientItem.id_supplier,
                                    tmp_supplier_name:
                                      ingredientItem.supplier_name,
                                    tmp_ing_price_expect:
                                      ingredientItem.ing_price_expect,
                                  };
                                }
                              ),
                            };
                          }),
                        };
                      }),
                    };
                  }),
                };
              }
            );

            setListQuantitatives(newListQuantitatives);

            setQuantitative(
              newListQuantitatives.find(
                (listQuantitativesItem) =>
                  listQuantitativesItem.id_day === dayId
              )
                ? newListQuantitatives.find(
                    (listQuantitativesItem) =>
                      listQuantitativesItem.id_day === dayId
                  )
                : newListQuantitatives[0]
            );

            setSelectedDay(
              newListQuantitatives.find(
                (listQuantitativesItem) =>
                  listQuantitativesItem.id_day === dayId
              )
                ? newListQuantitatives.find(
                    (listQuantitativesItem) =>
                      listQuantitativesItem.id_day === dayId
                  )?.id_day
                : newListQuantitatives[0].id_day
            );
          } catch (e) {
            toast(e.message, { type: "error" });
          }
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {});
  };

  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);
          setSelectedClassGroups(res.data.groups[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListSuppliers = async () => {
    await api
      .post("/viewSupplier")
      .then((res) => {
        if (res.data.status) {
          setSuplliers(
            res.data.data.map((supplierItem) => {
              return {
                id: supplierItem.id,
                supplier_name: supplierItem.supplier_name,
              };
            })
          );
        }
      })
      .catch((error) => {});
  };

  const getListIngredients = async () => {
    await api
      .get(`/ingredent-show?ingredent_type=1,2`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setIngredients(res.data.ingredents);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const saveQuantitative = async () => {
    setIsSendingForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("id", quantitative.id);
    bodyFormData.append("id_menu", menu.id_menu);
    bodyFormData.append("id_day", quantitative.id_day);
    bodyFormData.append("menu_date", quantitative.menu_date);
    bodyFormData.append(
      "costPerDays",
      JSON.stringify(quantitative.costPerDays)
    );
    bodyFormData.append(
      "times",
      JSON.stringify(
        quantitative.times.map((timeItem) => {
          return {
            ...timeItem,
            foods: timeItem.foods.map((foodItem) => {
              return {
                ...foodItem,
                groups: foodItem.groups.map((groupItem) => {
                  return {
                    ...groupItem,
                    ingredents: groupItem.ingredents.map((ingredientItem) => {
                      return {
                        ...ingredientItem,
                        ing_quantity: isChangeMarket
                          ? Number(ingredientItem.tmp_ing_quantity) || 0
                          : Number(ingredientItem.ing_quantity) || 0,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        })
      )
    );

    await api({
      method: "put",
      url: "meals-daily-save",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendingForm(false);

        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          let initialWeek = datesOfWeek(
            new Date(quantitative.menu_date * 1000)
          );

          getListQuantitatives(initialWeek[0], initialWeek[6], selectedDay);
          setIsChangeMarket(false);
          setIsChangeFood(false);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const handleUnSavedMealDaily = async (id_menu_day) => {
    swal({
      title: `Bạn chắc muốn hủy lưu số liệu tính khẩu phần ăn?`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "warning",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/meals-daily-unsaved`, {
            params: {
              id_menu_day: id_menu_day,
            },
          })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });

              let initialWeek = datesOfWeek(
                new Date(quantitative.menu_date * 1000)
              );

              getListQuantitatives(initialWeek[0], initialWeek[6], selectedDay);
              setIsChangeMarket(false);
              setIsChangeFood(false);
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          });
      }
    });
  };
  // #endregion

  //#region Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        foodItem.groups
          .find(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              groupItem.more_meal === null
          )
          ?.ingredents?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);
            return ingredientItem;
          });
        return foodItem;
      });
      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    // const ws1 = exportMarketBill(groupIngredientArray);
    const ws1 = exportMarketBill(
      groupIngredientArray,
      quantitative,
      selectedClassGroup.id
    );

    const ws2 = exportNutritionResult(groupIngredientArray, quantitative);
    const ws3 = exportNutritionSetupResult(groupIngredientArray, quantitative);

    XLSX.utils.book_append_sheet(wb, ws1, "Hóa đơn đi chợ");
    XLSX.utils.book_append_sheet(wb, ws2, "Kết quả dưỡng chất");
    XLSX.utils.book_append_sheet(wb, ws3, "Kết quả thiết lập dưỡng chất");
    // XLSX.utils.book_append_sheet(wb, ws4, "Hóa đơn đi chợ All");

    XLSX.writeFile(
      wb,
      `Tính khẩu phần ăn (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };

  const exportExcelAll = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        foodItem.groups
          .find(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              groupItem.more_meal === null
          )
          ?.ingredents?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);
            return ingredientItem;
          });
        return foodItem;
      });
      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    const ws4 = exportMarketBillAll(
      groupIngredientArray,
      quantitative,
      classGroups
    );
    XLSX.utils.book_append_sheet(wb, ws4, "Hóa đơn đi chợ tổng hợp");

    XLSX.writeFile(
      wb,
      `Hóa đơn đi chợ (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };

  const exportExcelAllByGroup = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];

    quantitative.times?.map((timeItem) => {
      timeItem.foods?.map((foodItem) => {
        foodItem.groups
          .find(
            (groupItem) =>
              groupItem.id === selectedClassGroup.id &&
              groupItem.more_meal === null
          )
          ?.ingredents?.map((ingredientItem) => {
            ingredientArray.push(ingredientItem);
            return ingredientItem;
          });
        return foodItem;
      });
      return timeItem;
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    const ws5 = exportMarketBillAllbyGroup(
      groupIngredientArray,
      quantitative,
      classGroups,
      selectedClassGroup,
      optionGroup
    );

    XLSX.utils.book_append_sheet(wb, ws5, `Hóa đơn đi chợ `);

    XLSX.writeFile(
      wb,
      `Hóa đơn đi chợ (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
    onHideOptionModal();
  };

  const handleXportPortionAll = () => {
    swal({
      title: `Bạn muốn xuất hóa đơn đi chợ?`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "warning",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcelAll();
      }
    });
  };

  const handleXportPortionAllByGroup = () => {
    exportExcelAllByGroup();
  };

  const handleXportPortion = () => {
    swal({
      title: `Bạn muốn xuất file khẩu phần ăn?`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "warning",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const handleCancelMarketChange = () => {
    swal({
      title: `Bạn muốn hủy thay đổi đi chợ?`,
      text: `Lưu ý: hành động không thể khôi phục!`,
      icon: "warning",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        setIsChangeMarket(false);
        setQuantitative({
          ...quantitative,
          times: quantitative.times.map((timeItem) => {
            return {
              ...timeItem,
              foods: timeItem.foods.map((foodItem) => {
                return {
                  ...foodItem,
                  groups: foodItem.groups.map((groupItem) => {
                    return {
                      ...groupItem,
                      // Handle change market quantitative
                      ingredents: groupItem.ingredents.map((ingredientItem) => {
                        return {
                          ...ingredientItem,
                          tmp_ing_quantity: ingredientItem.ing_quantity || 0,
                          id_supplier: ingredientItem.tmp_id_supplier,
                          supplier_name: ingredientItem.tmp_supplier_name,
                          ing_price_expect: ingredientItem.tmp_ing_price_expect,
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }),
        });
      }
    });
  };

  const handleCancelFoodChange = () => {
    swal({
      title: `Bạn muốn hủy thay đổi bếp?`,
      text: `Lưu ý: hành động không thể khôi phục!`,
      icon: "warning",
      buttons: ["Đóng", "Hủy"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        setIsChangeFood(false);
        setQuantitative({
          ...quantitative,
          times: quantitative.times.map((timeItem) => {
            return {
              ...timeItem,
              foods: timeItem.foods.map((foodItem) => {
                return {
                  ...foodItem,
                  groups: foodItem.groups.map((groupItem) => {
                    return {
                      ...groupItem,
                      ingredents: groupItem.ingredents.map((ingredientItem) => {
                        return {
                          ...ingredientItem,
                          ing_quantity: ingredientItem.tmp_ing_quantity || 0,
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }),
        });
      }
    });
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section className="mb-2">
        {/* <Card.Title>Chọn ngày tính khẩu phần ăn</Card.Title> */}

        <Row className="my-2">
          {listDays.map((dayItem) => {
            return (
              <Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
                <Form.Check
                  type="radio"
                  value={dayItem.id_day}
                  checked={selectedDay === dayItem.id_day}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const findListQuantitatives = listQuantitatives.find(
                        (quantitativeItem) =>
                          quantitativeItem.id_day === Number(e.target.value)
                      );

                      setSelectedDay(Number(e.target.value));

                      setQuantitative(
                        listQuantitatives.find(
                          (quantitativeItem) =>
                            quantitativeItem.id_day === Number(e.target.value)
                        )
                      );

                      getListSettings(
                        dataDays,
                        Number(e.target.value),
                        findListQuantitatives.menu_date
                      );
                    }
                  }}
                  name="day"
                  className="me-2"
                  disabled={isChangeMarket || isChangeFood}
                />
                {dayItem.day_name}
              </Col>
            );
          })}
        </Row>
      </section>
    );
  };
  const CustomInput = ({ value, onClick }) => (
    <Button variant="primary" onClick={onClick}>
      <i className="fa-solid fa-calendar" />
    </Button>
  );

  //#region Render
  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Tính khẩu phần ăn</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex align-items-center">
              <Card.Title className="me-2" style={{ zIndex: 99 }}>
                Tính khẩu phần ăn{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </Card.Title>

              <div
                className="me-2 d-flex align-items-center "
                id="calendar-nutrition"
              >
                <DatePicker
                  disabled={isLoading || isChangeMarket || isChangeFood}
                  selected={
                    dataDays?.length > 0
                      ? new Date(dataDays[0]?.menu_date * 1000)
                      : new Date()
                  }
                  startDate={
                    dataDays?.length > 0
                      ? new Date(dataDays[0]?.menu_date * 1000)
                      : new Date()
                  }
                  endDate={
                    dataDays?.length > 0
                      ? new Date(
                          dataDays[dataDays.length - 1]?.menu_date * 1000
                        )
                      : new Date()
                  }
                  onChange={(date) => {
                    const startDateGMT = startOfWeek(date, { weekStartsOn: 1 });
                    const endDateGMT = startOfWeek(
                      endOfWeek(date, { weekStartsOn: 1 })
                    );
                    getListQuantitatives(
                      new Date(startDateGMT),
                      new Date(endDateGMT),
                      selectedDay
                    );
                  }}
                  showWeekNumbers
                  dateFormat="yyyy-MM-dd"
                  calendarClassName="custom-calendar"
                  customInput={<CustomInput />}
                  locale={vi}
                />
              </div>
            </div>

            <div className="d-flex">
              <Select
                options={classGroups}
                placeholder={<div>Tất cả</div>}
                getOptionLabel={(option) => option.group_name}
                getOptionValue={(option) => option.id}
                value={selectedClassGroup}
                onChange={(choice) => {
                  setSelectedClassGroups(choice);
                }}
                className="me-2 in z-index-1021"
                isDisabled={isChangeFood || isChangeMarket}
              />

              {quantitative.is_meal_saved === 1 &&
              new Check().permission(["122"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => handleUnSavedMealDaily(quantitative.id)}
                  className="me-2"
                  disabled={
                    isLoading ||
                    isSendingForm ||
                    Object.keys(quantitative).length <= 1
                  }
                >
                  <i className="fa-solid fa-times" /> Hủy lưu số liệu
                </Button>
              ) : (
                ""
              )}

              {new Check().permission(["121"]) ? (
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => saveQuantitative()}
                  disabled={
                    isLoading ||
                    isSendingForm ||
                    Object.keys(quantitative).length <= 1
                  }
                >
                  <i className="fa-solid fa-check" /> Lưu số liệu
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card.Header>

        <Card.Body style={{ fontSize: ".9em" }} className="pt-1">
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : Object.keys(quantitative).length > 0 ? (
            <Row>
              <Col>
                {RenderOption()}
                <FoodQuantitativeTable
                  quantitative={quantitative}
                  setQuantitative={setQuantitative}
                  selectedClassGroup={selectedClassGroup}
                  ingredients={ingredients}
                  roundUp={roundUp}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Modal show={optionModal} onHide={() => onHideOptionModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Chọn nhóm cần xuất</Modal.Title>
            </Modal.Header>

            <Modal.Body className="text-center">
              <Select
                options={classGroups}
                placeholder={<div>Chọn nhóm</div>}
                getOptionLabel={(option) => option.group_name}
                getOptionValue={(option) => option.id}
                onChange={(choice) => {
                  setOptionGroup(choice);
                }}
                isMulti
                className="mb-2 mb-md-0  in z-index-1021"
                isDisabled={isChangeFood || isChangeMarket}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={onHideOptionModal}>
                <i className="fa-solid fa-times" /> Đóng
              </Button>

              <Button
                size="sm"
                variant="success mt-1"
                onClick={() => {
                  handleXportPortionAllByGroup();
                }}
                className="mb-2"
                disabled={
                  isLoading ||
                  isSendingForm ||
                  Object.keys(quantitative).length <= 1 ||
                  isChangeMarket ||
                  isChangeFood ||
                  optionGroup?.length < 1
                }
              >
                <i className="fa-solid fa-file-export" />
                Xuất File
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PortionCalculate;
