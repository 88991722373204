import { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Form, Button, Tabs, Tab } from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import ReCAPTCHA from "react-google-recaptcha";
import { message } from "../../../constants/message";
import PasswordInput from "../../uiElements/PasswordInput";

const ForgotPasswordModal = ({ show, onHide, onSave }) => {
  const [email, setEmail] = useState("");
  const [messagesErr, setMessagesErr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabKey, setTabKey] = useState(1);
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [captchaValue, setCaptchaValue] = useState(false);

  const handleSubmitForgotPassword = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      return setMessagesErr({
        ...messagesErr,
        captchaValue: message.pleaseCompleteTheReCAPTCHA,
      });
    }

    setIsLoading(true);
    api
      .post(
        "forgot-password",
        { email: email },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        toast(res.data.messages, { type: "success" });
        setTabKey(2);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.request.status === 400) {
          setMessagesErr(error.response.data.messages);
        } else {
          toast(error.response.data.messages, {
            type: "error",
          });
        }
        setIsLoading(false);
      });
  };

  const handleSubmitResetPassord = (e) => {
    e.preventDefault();

    setIsLoading(true);
    api
      .post(
        "reset-password",
        {
          token: token,
          password: password,
          confirmPassword: confirmPassword,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        toast(res.data.messages, { type: "success" });
        setTabKey(1);
        setIsLoading(false);
        return onHide();
      })
      .catch((error) => {
        if (error.request.status === 400) {
          setMessagesErr(error.response.data.messages);
        } else {
          toast(error.response.data.messages, {
            type: "error",
          });
        }
        setIsLoading(false);
      });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={() => onHide()}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Quên mật khẩu</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tabs
          variant="tabs"
          onSelect={(k) => setTabKey(Number(k))}
          activeKey={tabKey}
          className="mb-3 d-flex"
        >
          <Tab key={1} title="Nhập email" eventKey={1}>
            <Form onSubmit={handleSubmitForgotPassword}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Email"
                  name="email"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setMessagesErr({ ...messagesErr, email: "" });
                  }}
                />
                <Form.Label>
                  Email của bạn
                  <i className="text-danger">*</i>
                </Form.Label>
                <i className="text-danger">{messagesErr["email"]}</i>
              </Form.Floating>

              <div className="mb-3">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={(value) => {
                    setCaptchaValue(value);
                    setMessagesErr({
                      ...messagesErr,
                      captchaValue: "",
                    });
                  }}
                />
                <i className="text-danger">{messagesErr["captchaValue"]}</i>
              </div>

              <Modal.Footer className="px-0 pb-0">
                <Button variant="secondary me-2" onClick={() => onHide()}>
                  <i className="fa-solid fa-times"></i> Đóng
                </Button>

                <Button
                  type="submit"
                  variant="success m-0"
                  disabled={isLoading}
                >
                  <i className="fa-solid fa-arrow-right" /> Tiếp theo
                </Button>
              </Modal.Footer>
            </Form>
          </Tab>

          <Tab key={2} title="Thay đổi mật khẩu" eventKey={2}>
            <Form onSubmit={handleSubmitResetPassord}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Mã xác nhận OTP"
                  required
                  name="token"
                  onChange={(e) => {
                    setToken(e.target.value);
                    setMessagesErr({ ...messagesErr, token: "" });
                  }}
                />
                <Form.Label>
                  Mã xác nhận OTP
                  <i className="text-danger">*</i>
                </Form.Label>
                <span className="text-danger">{messagesErr["token"]}</span>
              </Form.Floating>

              <div className="mt-3">
                <PasswordInput
                  label="Mật khẩu mới"
                  name="password"
                  value={password}
                  placeholde="Mật khẩu mới"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setMessagesErr({ ...messagesErr, password: "" });
                  }}
                  required={true}
                />
                <span className="text-danger">{messagesErr["password"]}</span>
              </div>

              <div className="my-3">
                <PasswordInput
                  label="Nhập lại mật khẩu"
                  name="confirmPassword"
                  value={confirmPassword}
                  placeholde="Nhập lại mật khẩu"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setMessagesErr({ ...messagesErr, confirmPassword: "" });
                  }}
                  required={true}
                />
                <span className="text-danger">
                  {messagesErr["confirmPassword"]}
                </span>
              </div>

              <Modal.Footer className="px-0 pb-0">
                <Button variant="secondary me-2" onClick={() => onHide()}>
                  <i className="fa-solid fa-times"></i> Đóng
                </Button>
                <Button
                  variant="success m-0"
                  type="submit"
                  disabled={isLoading}
                >
                  <i className="fa-solid fa-check"></i> Thay đổi
                </Button>
              </Modal.Footer>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;
