import React, { Component } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import FormatData from "../FormatData";
import StoreCreate from "./StoreCreate";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Check from "../other/Check";
import CurrencyFormat from "react-currency-format";
import StoreBookModal from "./StoreBookModal";
import api from "../../../helper/axiosInstance";
import DatePickerCustom from "../../uiElements/DatePickerCustom";
const exportType = [
  { value: 3, label: "Hết hạn" },
  { value: 4, label: "Bị hỏng" },
  { value: 6, label: "Khác" },
];

class StoreIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liststore: [],
      selectedRows: [],
      has_inventory: [],
      inventory_his: [],
      addliststore: [],
      suppliers: [],
      showcreate: false,
      showdetail: false,
      showhis: false,
      showdelete: false,
      showupdate: false,
      startDate: new Date(),
      tabkey: 1,
      id_ingredent: "",
      amount_delete: 0,
      ing_price: 0,
      inventoryQuantity: 0,
      delete_type: { value: 3, label: "Hết hạn" },
      delete_note: "",
      ingredient_search: "",
      liststore_search: [],
      update_inventory: {
        id_inventory: "",
        is_type: false,
        amount: 0,
        ing_price: 0,
      },
      propsdata: {
        id: "",
        amount: "",
        ingredent_name: "",
      },
      endDate: new Date(),
      show: false,
      dataFilter: [],
      itemExport: {},
      itemLo: {},
      err: {},
      hisLo: {},
      showStoreBookModal: false,
      dataStoreBook: [],
      dateFilter: "",
    };
  }

  async componentDidMount() {
    document.title = "Quản lý kho";
    this.getSuppliersData();

    const date = new Date();

    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 0);

    this.setState(
      {
        startDate: start,
        endDate: end,
      },
      () => {
        this.getStore(this.state.startDate, this.state.endDate);
      }
    );
  }
  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  getStore = (start, end) => {
    let start_ = parseInt(start?.setHours(0, 0, 0) / 1000);
    let end_ = parseInt(end?.setHours(23, 59, 59) / 1000);
    api
      .get(`store-show?start_at=${start_}&term_at=${end_}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ liststore: res.data.ingredents }, () =>
            this.handleIngredentSearch(
              res.data.ingredents,
              this.state.ingredient_search
            )
          );
        } else {
          this.loading();
        }
      })
      .catch((error) => {});
  };

  getDate_ = (data) => {
    let date = data;
    let result = `${date?.getDate()}/${
      date?.getMonth() + 1
    }/${date?.getFullYear()}`;
    return result;
  };

  // getStoreData = (type, limit, start) => {
  //   let start_ = start.setHours(0, 0, 0, 0);
  //   let end_ = this.state.endDate.setHours(23, 59, 59, 0);
  //   api
  //     .post(
  //       `/viewInventory?ingredent_type=${type}&start_at=${start_ / 1000
  //       }&end_at=${end_ / 1000}&limit=${limit}`
  //     )
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         this.setState({ liststore: res.data.data });
  //         this.handleIngredentSearch(
  //           res.data.data,
  //           this.state.ingredient_search
  //         );
  //       } else {
  //         this.loading();
  //       }
  //     });
  // };

  getSuppliersData = () => {
    api
      .get(`/supplier-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ suppliers: res.data.suppliers });
        }
      })
      .catch((error) => {});
  };

  gethas_inventoryData = (type, item) => {
    let start_ = this.state.startDate.setHours(0, 0, 0, 0) / 1000;
    let end_ = this.state.endDate.setHours(23, 59, 59, 0) / 1000 + 1000;
    api
      .get(
        `lot-show?id_ingredent=${item.id_ingredent}&id_ing_type=${
          item.id_ing_type
        }&id_unit=${item.id_unit}&start_at=${parseInt(
          start_
        )}&term_at=${parseInt(end_)}`
      )
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ has_inventory: res.data.lots });
        }
      });
  };

  getinventory_hisData = (item) => {
    api
      .get(`lot-history?id=${item.id_lot}`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ inventory_his: res.data.ReceiptDetailHasLots });
        }
      })
      .catch((error) => {});
  };

  getaddliststore = (e) => {
    e.preventDefault();
    api
      .post(
        `/createMoreInventory?inventory=${JSON.stringify(
          this.state.selectedRows
        )}`
      )
      .then((res) => {
        if (res.data.status === true) {
          this.getStore(this.state.startDate, this.state.endDate);
          // this.getStoreData(this.state.tabkey + 1, 100, this.state.startDate);
          toast(res.data.data.mess, { type: "success", autoClose: 1000 });
        } else {
          toast(res.data.data.mess, { type: "error", autoClose: 1000 });
        }
      })
      .catch((error) => {});
  };

  deleteinventory = (e) => {
    e.preventDefault();
    let arr = [
      {
        id_ingredent: this.state.itemExport?.id_ingredent,
        ingredent_type: this.state.itemExport?.id_ing_type,
        id_unit_market: this.state.itemExport?.id_unit,
        ing_quantity: this.state.amount_delete,
        ing_price: this.state.ing_price,
        notes:
          this.state.delete_type?.value == 6
            ? this.state.delete_note
            : this.state.delete_type?.label,
      },
    ];
    let data = {
      rec_date: parseInt(new Date() / 1000),
      rec_note: "",
      ingredents: JSON.stringify(arr),
    };

    if (this.state.amount_delete <= 0) {
      this.setState({
        err: { "ingredents.0.ing_quantity": "Số lượng phải lớn hơn 0." },
      });
    } else if (
      this.state.amount_delete > parseFloat(this.state.inventoryQuantity)
    ) {
      this.setState({
        err: {
          "ingredents.0.ing_quantity":
            parseFloat(this.state.inventoryQuantity) <= 0
              ? `Đã hết hàng tồn kho.`
              : `Số lượng không lớn hơn ${this.state.inventoryQuantity}.`,
        },
      });
    } else if (
      this.state.delete_type.value == 6 &&
      this.state.delete_note == ""
    ) {
      toast("Vui lòng nhập lí do", { type: "error", autoClose: 1000 });
    } else {
      swal({
        title: `Bạn chắc muốn Xuất kho?`,
        icon: "warning",
        buttons: ["Đóng", "Đồng ý"],
        successMode: true,
      })
        .then((ok) => {
          if (ok) {
            api({
              method: "post",
              url: "store-export",
              data: data,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then((res) => {
                if (res.data.errCode === 0) {
                  this.getStore(this.state.startDate, this.state.endDate);
                  this.setState({
                    showdelete: false,
                    amount_delete: 0,
                  });
                  toast(res.data.message, { type: "success", autoClose: 1000 });
                } else {
                  this.setState({ err: res.data.message });
                  // toast(res.data.message, { type: "error", autoClose: 1000 });
                }
              })
              .catch((err) => {
                toast("Hệ thống xảy ra lỗi", {
                  type: "error",
                  autoClose: 1000,
                });
              });
          }
        })
        .catch((error) => {});
    }
  };

  updateinventory = (e) => {
    e.preventDefault();

    let data = {
      rec_notes: "",
      id_lot: this.state.update_inventory.id_inventory,
      ing_quantity: this.state.update_inventory.amount,
      ing_price: this.state.update_inventory?.is_type
        ? this.state.update_inventory.ing_price
        : this.state.ing_price,
      notes: this.state.update_inventory?.is_type
        ? "Nhập lô"
        : this.state.delete_type?.value == 6
        ? this.state.delete_note
        : this.state.delete_type?.label,
    };

    swal({
      title: `Bạn chắc muốn ${
        this.state.update_inventory.is_type ? "Nhập" : "Xuất"
      } lô?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api({
            method: "post",
            url: this.state.update_inventory?.is_type
              ? "lot-import"
              : "lot-export",
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((res) => {
              if (res.data.errCode === 0) {
                this.getStore(this.state.startDate, this.state.endDate);
                this.setState({ showupdate: false });
                this.gethas_inventoryData(this.state.tabkey, this.state.itemLo);
                toast(res.data.message, { type: "success", autoClose: 1000 });
              } else {
                this.setState({ err: res.data.message });
              }
            })
            .catch((err) => {
              toast("Hệ thống xảy ra lỗi", { type: "error", autoClose: 1000 });
            });
        }
      })
      .catch((error) => {});
  };

  deleteinventorychild = (id, event) => {
    swal({
      title: "Bạn muốn xóa lô?",
      icon: "error",
      buttons: true,
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .post(`/deleteInventory?id_inventory=${id}`)
            .then((res) => {
              this.getStore(this.state.startDate, this.state.endDate);
              toast(`${res.data.data.mess}`, {
                type: "success",
                autoClose: 1000,
              });
            })
            .catch((error) => {});
          this.setState({ showdetail: true });
        }
      })
      .catch((error) => {});
  };

  detailinventoryview = () => {
    return (
      <Modal
        size="lg"
        show={this.state.showdetail}
        onHide={() => {
          this.setState({ showdetail: false });
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Danh sách lô hàng - {this.state.itemLo?.ingredent_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflow: "auto" }}>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Mã lô</th>
                  <th className="text-end">Tồn kho</th>
                  <th className="text-center" style={{ minWidth: "150px" }}>
                    Nhà cung cấp
                  </th>
                  {this.state.tabkey == 2 ? null : (
                    <th className="text-center">Ngày hết hạn</th>
                  )}
                  <th className="text-center" style={{ minWidth: "250px" }}>
                    Phương thức
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.has_inventory.length > 0
                  ? this.state.has_inventory
                      .sort(function (a, b) {
                        return b.ing_exp_date - a.ing_exp_date;
                      })
                      .map((item, i) => {
                        let expriry_date = item.ing_exp_date
                          ? this.getDate(item.ing_exp_date)
                          : "";

                        return (
                          <tr key={i}>
                            <td>{item.lot_code || "Không"}</td>
                            <td className="text-end">
                              <CurrencyFormat
                                value={parseFloat(item.inventory_num)}
                                displayType={"text"}
                                thousandSeparator={true}
                                isNumericString
                                decimalScale={4}
                              />
                            </td>
                            <td className="text-center">
                              {item.supplier_name}
                            </td>
                            {this.state.tabkey == 2 ? null : (
                              <td className="text-center">{expriry_date}</td>
                            )}
                            <td className="text-center">
                              {new Check().permission(["47"]) ? (
                                <Button
                                  type="button"
                                  className="me-2"
                                  variant="success"
                                  size="sm"
                                  onClick={() => {
                                    this.setState({
                                      hisLo: item,
                                      showupdate: true,
                                      ing_price: item.ing_price || 0,
                                      update_inventory: {
                                        id_inventory: item.id_lot,
                                        is_type: true,
                                      },
                                    });
                                  }}
                                  disabled={item.lot_code ? false : true}
                                >
                                  Nhập lô
                                </Button>
                              ) : null}

                              {new Check().permission(["48"]) ? (
                                <Button
                                  type="button"
                                  className="me-2"
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    this.setState({
                                      ing_price: item.ing_price || 0,
                                      showupdate: true,
                                      err: {},
                                      hisLo: item,
                                      update_inventory: {
                                        id_inventory: item.id_lot,
                                        is_type: false,
                                      },
                                    });
                                  }}
                                  disabled={item.lot_code ? false : true}
                                >
                                  Xuất lô
                                </Button>
                              ) : null}

                              {new Check().permission(["50"]) ? (
                                <Button
                                  type="button"
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.getinventory_hisData(item);
                                    this.setState({
                                      showhis: true,
                                      hisLo: item,
                                    });
                                  }}
                                  disabled={item.lot_code ? false : true}
                                >
                                  Lịch sử
                                </Button>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })
                  : null}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              this.setState({ showdetail: false });
            }}
          >
            <i className="fa-solid fa-times" /> Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  hisinventoryview = () => {
    return (
      <Modal
        size="lg"
        show={this.state.showhis}
        onHide={() => this.setState({ showhis: false, showdetail: true })}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Lịch sử lô{" "}
            {this.state.hisLo?.lot_code !== null
              ? `- ${this.state.hisLo?.lot_code}`
              : ""}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped>
            <thead>
              <tr>
                <th>Loại</th>
                <th className="text-end">Số lượng</th>
                <th className="text-end">Thời gian cập nhật</th>
                <th>Nhập bởi</th>
              </tr>
            </thead>
            <tbody>
              {this.state.inventory_his
                .sort(function (a, b) {
                  return b.created_at - a.created_at;
                })
                .map((item, i) => {
                  let created_at = new FormatData().date(item.rec_date);

                  const ingQuantity =
                    (item.is_type === 1 ? "" : "-") +
                    Number(item.ing_quantity).toFixed(4);

                  return (
                    <tr key={i}>
                      <td>{item.is_type ? "Nhập" : "Xuất"}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(ingQuantity)}
                          displayType={"text"}
                          thousandSeparator={true}
                          isNumericString
                          decimalScale={4}
                        />
                      </td>
                      <td className="text-end">{created_at}</td>
                      <td>{item.updater}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => this.setState({ showhis: false, showdetail: true })}
          >
            <i className="fa-solid fa-times"></i> Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  deleteinventoryview = () => {
    return (
      <Modal
        size="md"
        show={this.state.showdelete}
        onHide={() => {
          this.setState({
            showdelete: false,
            err: {},
            amount_delete: 0,
          });
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Xuất kho</Modal.Title>
        </Modal.Header>

        <Form onSubmit={this.deleteinventory}>
          <Modal.Body>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                disabled
                value={this.state.itemExport?.ingredent_name}
              />
              <Form.Label>Tên nguyên liệu</Form.Label>
            </Form.Floating>

            <Row>
              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    name="ing_price"
                    value={this.state.ing_price}
                    allowNegative={false}
                    thousandSeparator={true}
                    placeholder="Giá xuất"
                    required
                    onValueChange={(values) => {
                      this.setState({ ing_price: values.value });
                    }}
                  />
                  <Form.Label>
                    Giá xuất<i className="text-danger">*</i> (VND)
                  </Form.Label>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating className="mb-3">
                  <CurrencyFormat
                    className="form-control text-end"
                    name="amount_delete"
                    value={this.state.amount_delete}
                    allowNegative={false}
                    thousandSeparator={true}
                    placeholder="Số lượng"
                    required
                    onValueChange={(values) => {
                      delete this.state.err[`ingredents.${0}.ing_quantity`];
                      this.setState({ amount_delete: values.value });
                    }}
                  />

                  <Form.Label>
                    Số lượng<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-form-err">
                    {this.state.err[`ingredents.${0}.ing_quantity`]}
                  </span>
                </Form.Floating>
              </Col>
            </Row>

            <Select
              options={exportType}
              placeholder={<div>Nhập lý do xuất kho</div>}
              value={this.state.delete_type}
              onChange={(choice) => {
                delete this.state.err[`ingredents.${0}.notes`];

                this.setState({ delete_type: choice, delete_note: "" });
              }}
              // className="mb-3"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: "50px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }),
              }}
            />

            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                value={this.state.delete_note}
                onChange={(e) => {
                  delete this.state.err[`ingredents.${0}.notes`];
                  this.setState({ delete_note: e.target.value });
                }}
                name="amount"
                disabled={this.state.delete_type.value !== 6}
                required={this.state.delete_type.value == 6}
              />
              <Form.Label>
                Lý do
                <i className="text-danger">
                  {this.state.delete_type.value == 6 ? "*" : ""}
                </i>
              </Form.Label>
              <span className="text-form-err">
                {this.state.err[`ingredents.${0}.notes`]}
              </span>
            </Form.Floating>
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() =>
                this.setState({
                  showdelete: false,
                  err: {},
                  amount_delete: 0,
                })
              }
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>
            <Button size="sm" type="submit" variant="success">
              <i className="fa-solid fa-check"></i> Lưu
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  updateinventoryview = () => {
    return (
      <Modal
        size="md"
        show={this.state.showupdate}
        onHide={() => {
          this.setState({ showupdate: false, showdetail: true, err: {} });
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.update_inventory.is_type ? "Nhập lô" : "Xuất lô"} -{" "}
            {this.state.hisLo?.lot_code}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.updateinventory}>
          <Modal.Body>
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                value={this.state.itemLo?.ingredent_name ?? ""}
                placeholder="Tên nguyên liệu"
                disabled
              />
              <Form.Label>Tên nguyên liệu</Form.Label>
            </Form.Floating>
            <Row>
              <Col>
                <Form.Floating>
                  <CurrencyFormat
                    className="form-control text-end"
                    name="ing_price"
                    value={this.state.ing_price}
                    allowNegative={false}
                    thousandSeparator={true}
                    placeholder="Giá"
                    disabled={this.state.update_inventory.is_type}
                    required
                    onValueChange={(values) => {
                      delete this.state.err["ing_price"];
                      this.setState({ ing_price: values.value });
                    }}
                  />
                  <Form.Label>
                    Giá<i className="text-danger">*</i> (VND)
                  </Form.Label>
                  <span className="text-form-err">
                    {this.state.err?.ing_price}
                  </span>
                </Form.Floating>
              </Col>
              <Col>
                <Form.Floating>
                  <CurrencyFormat
                    name="amount"
                    thousandSeparator={true}
                    className="form-control"
                    isNumericString
                    decimalScale={4}
                    onChange={(e) => {
                      delete this.state.err["ing_quantity"];
                      this.setState({
                        update_inventory: {
                          id_inventory:
                            this.state.update_inventory.id_inventory,
                          is_type: this.state.update_inventory.is_type,
                          amount: e.target.value,
                        },
                      });
                    }}
                    placeholder="Số lượng"
                  />

                  <Form.Label>
                    Số lượng<i className="text-danger">*</i>
                  </Form.Label>
                  <span className="text-form-err">
                    {this.state.err?.ing_quantity}
                  </span>
                </Form.Floating>
              </Col>
            </Row>

            {this.state.update_inventory.is_type ? (
              ""
            ) : (
              <>
                <Select
                  options={exportType}
                  placeholder={<div>Nhập lý do xuất kho</div>}
                  value={this.state.delete_type}
                  onChange={(choice) => {
                    this.setState({
                      delete_type: choice,
                      delete_note: "",
                    });
                  }}
                  className="mb-3"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minHeight: "50px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }),
                  }}
                />

                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    value={this.state.delete_note}
                    onChange={(e) => {
                      delete this.state.err["notes"];
                      this.setState({ delete_note: e.target.value });
                    }}
                    name="amount"
                    disabled={this.state.delete_type.value !== 6}
                    required={this.state.delete_type.value == 6}
                  />
                  <Form.Label>
                    Lý do
                    <i className="text-danger">
                      {this.state.delete_type.value == 6 ? "*" : ""}
                    </i>
                  </Form.Label>
                  <span className="text-form-err">{this.state.err?.notes}</span>
                </Form.Floating>
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => this.setState({ showupdate: false })}
            >
              <i className="fa-solid fa-times"></i> Đóng
            </Button>

            <Button size="sm" type="submit" variant="success">
              <i className="fa-solid fa-check"></i> Lưu
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  getDate = (data) => {
    let date = new Date(data * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };

  dataTable(key) {
    const columns = [
      {
        name: "Nguyên liệu",
        selector: (row) => row.ingredent_name,
        sortable: true,
        style: {
          div: {
            width: "100px !important",
          },
        },
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.ingredent_name}
          </div>
        ),
      },
      {
        name: "Đơn vị tính",
        selector: (row) => row.unit_name,
        sortable: true,
        center: true,
      },
      {
        name: "Tồn đầu kỳ",
        selector: (row) => row.beginning_balance,
        sortable: true,
        right: true,
        cell: (row) => (
          <CurrencyFormat
            value={parseFloat(row.beginning_balance) || 0}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={4}
          />
        ),
      },

      {
        name: "Nhập trong kỳ",
        selector: (row) => row.total_qty_import,
        sortable: true,
        right: true,
        cell: (row) => (
          <CurrencyFormat
            value={parseFloat(row.total_qty_import)}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={4}
          />
        ),
      },
      {
        name: "Xuất trong kỳ",
        selector: (row) => row.total_qty_export,
        sortable: true,
        right: true,
        cell: (row) => (
          <CurrencyFormat
            value={parseFloat(row.total_qty_export)}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={4}
          />
        ),
      },
      {
        name: "Tồn cuối kỳ",
        selector: (row) => row.total,
        sortable: true,
        right: true,
        cell: (row) => (
          <CurrencyFormat
            value={parseFloat(row.total)}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={4}
          />
        ),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
        style: { minWidth: "200px" },
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.liststore_search.length > 0) {
      const sortedIngredients = this.state.liststore_search.sort((a, b) =>
        a.ingredent_name.localeCompare(b.ingredent_name)
      );

      sortedIngredients.map((item) => {
        if (item.id_ing_type == key) {
          const TOTAL =
            parseFloat(item.beginning_balance ?? 0) +
            parseFloat(item.total_qty_import ?? 0) -
            parseFloat(item.total_qty_export ?? 0);

          data.push({
            id: item.id,
            ingredent_name: item.ingredent_name,
            unit_name: item.unit_name,
            beginning_balance:
              Math.round(parseFloat(item.beginning_balance ?? 0) * 10000) /
              10000,
            beginning_inventory_value: Math.round(
              parseFloat(item.beginning_inventory_value ?? 0)
            ),
            ending_balance:
              Math.round(parseFloat(item.ending_balance ?? 0) * 10000) / 10000,
            ending_inventory_value: Math.round(
              parseFloat(item.ending_inventory_value ?? 0)
            ),
            total_qty_import:
              Math.round(parseFloat(item.total_qty_import ?? 0) * 10000) /
              10000,
            total_qty_export:
              Math.round(parseFloat(item.total_qty_export ?? 0) * 10000) /
              10000,
            total_money_import: Math.round(
              parseFloat(item.total_money_import ?? 0)
            ),
            total_money_export: Math.round(
              parseFloat(item.total_money_export ?? 0)
            ),
            total: Math.round(TOTAL * 10000) / 10000,
            inventory_termSlot:
              Math.round(parseFloat(item.inventory_termSlot ?? 0) * 10000) /
              10000,
            setting: (
              <div style={{ width: "200px" }} className="text-wrap">
                {new Check().permission(["47"]) ? (
                  <Button
                    type="button"
                    className="me-2"
                    variant="success"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        propsdata: {
                          id: item.id_ingredent,
                          amount: item.amount,
                          ingredent_name: item.ingredent_name,
                          id_unit: item.id_unit,
                        },
                        showcreate: true,
                      });
                    }}
                    title={`Nhập kho theo nguyên liệu ${item.ingredent_name}`}
                  >
                    Nhập
                  </Button>
                ) : null}
                {new Check().permission(["48"]) ? (
                  <Button
                    type="button"
                    className="me-2 "
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      if (TOTAL > 0)
                        this.setState({
                          showdelete: true,
                          id_ingredent: item.id_ingredent,
                          itemExport: item,
                          ing_price: item.ing_price || 0,
                          inventoryQuantity: this.formatter.format(TOTAL) || 0,
                        });
                      else
                        toast("Đã hết hàng tồn kho.", {
                          type: "error",
                          autoClose: 1000,
                        });
                    }}
                    title={`Xuất kho theo nguyên liệu ${item.ingredent_name}`}
                  >
                    Xuất
                  </Button>
                ) : (
                  <></>
                )}

                {new Check().permission(["50"]) ? (
                  <Button
                    type="button"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      this.gethas_inventoryData(this.state.tabkey, item);
                      this.setState({
                        showdetail: true,
                        itemLo: item,
                      });
                    }}
                    title={`Xem lô hàng ${item.ingredent_name} trong kỳ`}
                  >
                    Lô hàng
                  </Button>
                ) : null}
              </div>
            ),
          });
        }

        return item;
      });
    }

    return (
      <>
        <Button
          variant="primary"
          size="sm"
          className="position-absolute"
          style={{ top: "8rem", right: "1rem" }}
          onClick={() => {
            this.setState({
              dataStoreBook: data,
              showStoreBookModal: true,
              dateFilter:
                this.state.startDate && this.state.endDate
                  ? `${this.getDate_(this.state.startDate)} - ${this.getDate_(
                      this.state.endDate
                    )}`
                  : "",
            });
          }}
        >
          <i className="fa-solid fa-file" /> Sổ kho
        </Button>

        <DataTables
          data={data}
          columns={columns}
          selectedRows={selectedRows}
          // select={key === 2 ? true : false}
        />
      </>
    );
  }

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text) {
    let root_ = root;
    this.setState({
      ingredient_search: text,
    });
    if (text !== "") {
      root_ = this.state.liststore.filter((e) =>
        this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim()))
      );
    }
    this.setState({
      liststore_search: root_,
    });
  }

  handSelectAddMore(item) {
    let var_ = {
      ingredent_name: item.ingredent_name,
      id_ingerdent: item.id,
      supplier: [],
      amount: 1,
      expriry_date: new Date() / 1000,
    };
    let selectedRows_ = this.state.selectedRows;
    if (selectedRows_ !== "") {
      selectedRows_.forEach((e, index) => {
        if (e.id_ingerdent === item.id) {
          selectedRows_[index].amount =
            parseInt(selectedRows_[index].amount) + 1;
        } else {
          if (selectedRows_.length < this.state.liststore.length) {
            selectedRows_.push(var_);
          }
        }
      });
    } else {
      selectedRows_ = [var_];
    }
    this.setState({ selectedRows: selectedRows_ });
  }

  renderStoreList() {
    return (
      <>
        <Card.Header style={{ display: "flex" }}>
          <Card.Title>
            <i className="fas fa-list me-1"></i> Tồn kho{" "}
            {this.state.startDate ? this.getDate_(this.state.startDate) : ""} -{" "}
            {this.state.endDate ? this.getDate_(this.state.endDate) : "--"}
          </Card.Title>
          <div style={{ marginLeft: "10px" }}>
            <Button
              size="sm"
              onClick={() => {
                this.setState({ show: true });
              }}
            >
              <i className="fa-solid fa-calendar-days"> </i>
            </Button>
          </div>
        </Card.Header>

        <Card.Body>
          <Col lg={3} md={4} className="mb-2">
            <Form.Control
              style={{ height: 39 }}
              type="text"
              placeholder="Tìm nguyên liệu"
              value={this.state.ingredient_search}
              onChange={(e) => {
                this.handleIngredentSearch(
                  this.state.liststore,
                  e.target.value
                );
              }}
            />
          </Col>

          <Tabs
            activeKey={this.state.tabkey}
            onSelect={(k) => {
              this.setState({ tabkey: k });
              this.getStore(this.state.startDate, this.state.endDate);
              // this.getStoreData(Number(k) + 1, 100, this.state.startDate);
              this.handleIngredentSearch(this.state.liststore, "");
              this.setState({ ingredient_search: "" });
            }}
            className="mb-3 position-relative"
          >
            <Tab key={1} title="Kho" eventKey={1} className="">
              {this.state.liststore_search?.length > 0 ? (
                this.dataTable(2)
              ) : (
                <p className="text-center">Không có dữ liệu ...</p>
              )}
            </Tab>

            <Tab
              style={{ height: "650px", overflowY: "auto" }}
              key={2}
              title="Vật tư"
              eventKey={2}
              className=""
            >
              {this.state.liststore_search.length > 0 ? (
                this.dataTable(3)
              ) : (
                <p className="text-center">Không có dữ liệu ...</p>
              )}
            </Tab>
          </Tabs>
        </Card.Body>

        {this.state.showStoreBookModal ? (
          <StoreBookModal
            show={this.state.showStoreBookModal}
            onHide={() =>
              this.setState({
                showStoreBookModal: false,
                dataStoreBook: [],
              })
            }
            data={this.state.dataStoreBook}
            dateFilter={this.state.dateFilter}
          />
        ) : (
          <></>
        )}
      </>
    );
  }

  renderStoreAdd() {
    return this.state.showcreate ? (
      <StoreCreate
        show={this.state.showcreate}
        tabkey={this.state.tabkey}
        onHide={() => {
          this.setState({ showcreate: false });
        }}
        getStoreData={(key) => {
          this.setState({ showcreate: false });
          this.getStore(this.state.startDate, this.state.endDate);
          // this.getStoreData(Number(key) + 1, 100, this.state.startDate);
        }}
        suppliers={this.state.suppliers}
        propsdata={this.state.propsdata}
      />
    ) : null;
  }

  render() {
    return (
      <>
        <Container fluid>
          {/* {this.renderSelectedRows()} */}
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Xuất-Nhập-Tồn</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card>{this.renderStoreList()}</Card>
            </Col>
          </Row>
        </Container>

        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          size={"sm"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Chọn thời gian</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <DatePickerCustom
                selected={this.state.startDate}
                onChange={(update) => {
                  this.setState({ startDate: update[0] });
                  this.setState({ endDate: update[1] });
                }}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                // minDate={new Date()}
                selectsRange
                inline
              />
            </center>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" onClick={() => this.setState({ show: false })}>
              Hủy
            </Button>
            <Button
              size="sm"
              disabled={
                this.state.startDate === null || this.state.endDate === null
              }
              onClick={() => {
                this.setState({ show: false });
                this.getStore(this.state.startDate, this.state.endDate);
                // this.getStoreData(parseInt(this.state.tabkey) + 1, 100, this.state.startDate)
              }}
            >
              Lưu
            </Button>
          </Modal.Footer>
        </Modal>

        {this.detailinventoryview()}
        {this.updateinventoryview()}
        {this.deleteinventoryview()}
        {this.hisinventoryview()}
        {this.renderStoreAdd()}
      </>
    );
  }
}

export default StoreIndex;
