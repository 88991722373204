import { Form, Table } from "react-bootstrap";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../../utils/helpers";

const MarketListTable = ({
  voucher,
  ingredients = [],
  setIngredients,
  school,
  suppliers,
  isChangeVoucherMarket,
}) => {
  if (!voucher) {
    return "";
  }

  let marketMoney = 0;
  let storeMoney = 0;

  return (
    <div style={{ overflowX: "scroll" }} className="table-responsive-600">
      <Table bordered style={{ minWidth: 1024 }}>
        <thead>
          <tr className="text-center">
            <th colSpan="9">
              Tổng hợp định lượng đi chợ{" "}
              {dateFormatter(new Date(voucher.menu_date * 1000))}
            </th>
          </tr>
          <tr className="text-center tr-sticky-first">
            <th>STT</th>
            <th>NGUYÊN LIỆU</th>
            <th>NHÀ CUNG CẤP</th>
            <th>ĐƠN VỊ</th>
            <th>SỐ LƯỢNG</th>
            <th>ĐƠN GIÁ</th>
            <th>THÀNH TIỀN</th>
            <th>GHI CHÚ</th>
          </tr>
        </thead>
        <tbody>
          {ingredients?.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 1
          ).length !== 0 ? (
            <>
              {ingredients
                ?.filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 1
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientQuantity = Number(
                    ingredientItem.ing_quantity || 0
                  ).toFixed(2);

                  const ingredientItemPrice = Number(
                    parseFloat(ingredientQuantity) * ingredientItem.ing_price
                  ).toFixed(0);

                  marketMoney += parseFloat(ingredientItemPrice);

                  return (
                    <tr
                      key={`ingredient-key-2-${ingredientItem.id_ingredent}`}
                      className="align-middle"
                    >
                      <td className="text-center">{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name}</td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Select
                            options={suppliers}
                            getOptionLabel={(option) => option.supplier_name}
                            getOptionValue={(option) => option.id}
                            value={{
                              id: ingredientItem.id,
                              supplier_name: ingredientItem.supplier_name,
                            }}
                            placeholder={<div>Chọn nhà cung cấp</div>}
                            onChange={(choice) => {
                              setIngredients(
                                ingredients.map((ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      id_supplier: choice.id,
                                      supplier_name: choice.supplier_name,
                                    };
                                  }

                                  return ingredientObj;
                                })
                              );
                            }}
                            menuPortalTarget={document.querySelector("body")}
                          />
                        ) : (
                          ingredientItem.supplier_name
                        )}
                      </td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientQuantity}
                          displayType={
                            !isChangeVoucherMarket ? "text" : "input"
                          }
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            setIngredients(
                              ingredients.map((ingredientObj) => {
                                if (
                                  ingredientItem.id_ingredent ===
                                  ingredientObj.id_ingredent
                                ) {
                                  return {
                                    ...ingredientObj,
                                    ing_quantity: values.value,
                                  };
                                }

                                return ingredientObj;
                              })
                            );
                          }}
                          className={`${
                            isChangeVoucherMarket ? "form-control" : ""
                          } text-end`}
                          allowNegative={false}
                          disabled={!isChangeVoucherMarket}
                          decimalScale={2}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientItem.ing_price}
                          displayType={
                            !isChangeVoucherMarket ? "text" : "input"
                          }
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            setIngredients(
                              ingredients.map((ingredientObj) => {
                                if (
                                  ingredientItem.id_ingredent ===
                                  ingredientObj.id_ingredent
                                ) {
                                  return {
                                    ...ingredientObj,
                                    ing_price: values.value,
                                  };
                                }

                                return ingredientObj;
                              })
                            );
                          }}
                          className={`${
                            isChangeVoucherMarket ? "form-control" : ""
                          } text-end`}
                          allowNegative={false}
                          isNumericString
                          disabled={!isChangeVoucherMarket}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(ingredientItemPrice)}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          isNumericString
                          decimalScale={0}
                        />
                      </td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Form.Control
                            type="text"
                            value={
                              ingredientItem.vou_notes
                                ? ingredientItem.vou_notes
                                : ""
                            }
                            onChange={(e) => {
                              setIngredients(
                                ingredients.map((ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      vou_notes: e.target.value,
                                    };
                                  }

                                  return ingredientObj;
                                })
                              );
                            }}
                            disabled={!isChangeVoucherMarket}
                          />
                        ) : (
                          ingredientItem.vou_notes
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>CỘNG</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={marketMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th></th>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                Không có hàng chợ
              </td>
            </tr>
          )}

          {/* Store */}
          {ingredients?.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 2
          ).length !== 0 ? (
            <>
              {ingredients
                ?.filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 2
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientQuantity = Number(
                    ingredientItem.ing_quantity || 0
                  ).toFixed(2);

                  const ingredientItemPrice = Number(
                    parseFloat(ingredientQuantity) * ingredientItem.ing_price
                  ).toFixed(0);

                  storeMoney += parseFloat(ingredientItemPrice);

                  return (
                    <tr
                      key={`ingredient-key-3-${ingredientItem.id_ingredent}`}
                      className="align-middle"
                    >
                      <td className="text-center">{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name} (Kho)</td>
                      <td>{ingredientItem.supplier_name} </td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientQuantity}
                          displayType={
                            !isChangeVoucherMarket ? "text" : "input"
                          }
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            setIngredients(
                              ingredients.map((ingredientObj) => {
                                if (
                                  ingredientItem.id_ingredent ===
                                  ingredientObj.id_ingredent
                                ) {
                                  return {
                                    ...ingredientObj,
                                    ing_quantity: values.value,
                                  };
                                }

                                return ingredientObj;
                              })
                            );
                          }}
                          className={`${
                            isChangeVoucherMarket ? "form-control" : ""
                          } text-end`}
                          allowNegative={false}
                          disabled={!isChangeVoucherMarket}
                          decimalScale={2}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientItem.ing_price}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          allowNegative={false}
                          isNumericString
                          disabled={true}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(ingredientItemPrice)}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          decimalScale={0}
                        />
                      </td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Form.Control
                            type="text"
                            value={
                              ingredientItem.vou_notes
                                ? ingredientItem.vou_notes
                                : ""
                            }
                            onChange={(e) => {
                              setIngredients(
                                ingredients.map((ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      vou_notes: e.target.value,
                                    };
                                  }

                                  return ingredientObj;
                                })
                              );
                            }}
                            disabled={!isChangeVoucherMarket}
                            className="text-start"
                          />
                        ) : (
                          ingredientItem.vou_notes
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>CỘNG</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={storeMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th></th>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                Không có hàng kho
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Table
        bordered
        style={{ minWidth: 1024 }}
        className="d-none"
        id="market-list-table"
      >
        <thead>
          <tr>
            <th>{school.school_name?.toUpperCase()}</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th>
              Tổng hợp định lượng đi chợ{" "}
              {dateFormatter(new Date(voucher.menu_date * 1000))}
            </th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th>STT</th>
            <th>NGUYÊN LIỆU</th>
            <th>NHÀ CUNG CẤP</th>
            <th>ĐƠN VỊ</th>
            <th>SỐ LƯỢNG</th>
            <th>ĐƠN GIÁ</th>
            <th>THÀNH TIỀN</th>
            <th>GHI CHÚ</th>
          </tr>
        </thead>
        <tbody>
          {ingredients?.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 1
          ).length !== 0 ? (
            <>
              {ingredients
                ?.filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 1
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientQuantity = Number(
                    ingredientItem.ing_quantity || 0
                  ).toFixed(2);
                  const ingredientItemPrice = Math.round(
                    Number(
                      parseFloat(ingredientQuantity) * ingredientItem.ing_price
                    ).toFixed(0)
                  );

                  return (
                    <tr
                      key={`ingredient-key-0-${ingredientItem.id_ingredent}`}
                      className="align-middle"
                    >
                      <td>{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name}</td>
                      <td>{ingredientItem.supplier_name}</td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td>{ingredientQuantity}</td>
                      <td>{ingredientItem.ing_price}</td>
                      <td>{ingredientItemPrice}</td>
                      <td>
                        {ingredientItem.vou_notes
                          ? ingredientItem.vou_notes
                          : "empty"}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>CỘNG</th>
                <th>
                  <CurrencyFormat
                    value={marketMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th>&nbsp;</th>
              </tr>
            </>
          ) : (
            <tr>
              <td>Không có hàng chợ</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
          )}
          {/* Store */}
          {ingredients?.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 2
          ).length !== 0 ? (
            <>
              {ingredients
                ?.filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 2
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientQuantity = Number(
                    ingredientItem.ing_quantity
                  ).toFixed(2);

                  const ingredientItemPrice = Number(
                    parseFloat(ingredientQuantity) * ingredientItem.ing_price
                  ).toFixed(0);

                  return (
                    <tr key={`ingredient-key-1-${ingredientItem.id_ingredent}`}>
                      <td>{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name} (Kho)</td>
                      <td>{ingredientItem.supplier_name}</td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td>{ingredientQuantity}</td>
                      <td>{ingredientItem.ing_price}</td>
                      <td>{ingredientItemPrice}</td>
                      <td>
                        {ingredientItem.vou_notes
                          ? ingredientItem.vou_notes
                          : "empty"}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>CỘNG</th>
                <th>
                  <CurrencyFormat
                    value={storeMoney}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th>&nbsp;</th>
              </tr>
            </>
          ) : (
            <tr>
              <td>Không có hàng kho</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
          )}
        </tbody>
        <tfoot></tfoot>
      </Table>
    </div>
  );
};

export default MarketListTable;
