import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Row, Col, Button, Table } from "react-bootstrap";
import {
  priceFormatterNoDecimal,
  capitalizeFirstLetter,
  soThanhChu,
} from "../utils/helpers";
import { toast } from "react-toastify";

const ComponentToPrint = ({ item, schoolInfo }) => {
  return (
    <div
      style={{
        fontFamily: "Times New Roman, Times, serif",
        color: "#000",
        pageBreakAfter: "always",
      }}
      className="hidden-print"
    >
      <div>{schoolInfo?.school_name}</div>
      <div>
        {schoolInfo?.school_address}. ĐT: {schoolInfo?.school_phone}
      </div>
      <div>
        <div
          style={{
            display: "flex",
            fontSize: "16px",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          PHIẾU THU {new Date(item?.bill_date * 1000).getMonth() + 1}/
          {new Date(item?.bill_date * 1000).getFullYear()}
        </div>
        <div style={{ height: "40px" }}>
          <div
            style={{
              display: "flex",
              fontSize: "16px",
              // justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {item?.student?.student_name} :{" "}
            {item?.student?.classes[0]?.class_name}
          </div>
        </div>
        <Table bordered style={{ border: "1px solid" }}>
          <thead>
            <tr>
              <th className="text-center align-middle">STT</th>
              <th className="text-start align-middle">DỊCH VỤ</th>
              <th className="text-end align-middle">SL</th>

              <th className="text-end align-middle">ĐƠN GIÁ</th>
              <th className="text-end align-middle">Thành tiền</th>
            </tr>
          </thead>
          <tbody>
            {item?.details?.map((detail, index) => (
              <tr key={index} className="align-middle">
                <td className="text-center align-middle">{index + 1}</td>
                <td>{detail?.content}</td>
                <td className="text-end align-middle">
                  {priceFormatterNoDecimal.format(detail?.quantity)}
                </td>
                <td className="text-end align-middle">
                  {priceFormatterNoDecimal.format(detail?.price)}
                </td>

                <td className="text-end align-middle">
                  {priceFormatterNoDecimal.format(
                    detail.bill_detailable === "HolidayRefunded" ||
                      detail.bill_detailable === "FoodRefunded" ||
                      detail.bill_detailable === "DiscountRefunded"
                      ? 0 - detail?.sub_total
                      : detail?.sub_total
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={4}>TỔNG CỘNG</th>
              <th className="text-end">
                {priceFormatterNoDecimal.format(item?.trans_amount)}
              </th>
            </tr>
          </tfoot>
        </Table>
        <div
          style={{
            height: "20px",
            marginTop: "-10px",
            fontStyle: "italic",
            fontWeight: "inherit !important",
          }}
        >
          Bằng chữ: {capitalizeFirstLetter(soThanhChu(item?.trans_amount))}.
        </div>
        <div style={{ height: "10px" }}></div>
        <div>Ghi chú: {item.bill_notes}</div>
      </div>
      <div className="text-end">
        Ngày .... tháng {new Date(item?.bill_date * 1000).getMonth() + 1} năm{" "}
        {new Date(item?.bill_date * 1000).getFullYear()}{" "}
      </div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-around",
          fontWeight: "bold",
          marginTop: "15px",
          border: "none !important",
        }}
      >
        <Col className="text-center">Người nộp tiền</Col>
        <Col className="text-center">Người thu</Col>
        <Col className="text-center">Hiệu trưởng</Col>
      </Row>
    </div>
  );
};

const PrintMultipleButton = ({ schoolInfo, item, choiceClass }) => {
  const componentRef = useRef();

  const printAction = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Danh sách phiếu thu " + choiceClass?.label,
  });

  const handlePrint = () => {
    if (choiceClass?.value < 1) {
      toast("Vui lòng chọn lớp để xuất", {
        type: "error",
        autoClose: 1000,
      });
      return;
    } else if (item?.length < 1) {
      toast("Vui lòng chọn phiếu thu", {
        type: "error",
        autoClose: 1000,
      });
    } else {
      printAction();
    }
  };

  return (
    <div>
      <div ref={componentRef}>
        {item.map((item) => (
          <ComponentToPrint key={item.id} item={item} schoolInfo={schoolInfo} />
        ))}
      </div>
      <Button
        variant="danger mt-2"
        size="sm"
        onClick={handlePrint}
        title={
          choiceClass?.value < 1
            ? "Vui lòng chọn lớp để xuất"
            : item?.length < 1
            ? "Chọn phiếu thu để xuất"
            : "Xuất phiếu thu"
        }
      >
        <i className="fa-solid fa-file-pdf"></i> Xuất PDF
      </Button>
    </div>
  );
};

export default PrintMultipleButton;
