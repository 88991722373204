import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import * as XLSX from "xlsx-js-style";
import { Button, Card, Container, Row, Spinner, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import {
  groupArrayByField,
  priceFormatter,
  findeQuantitativeZone,
  isInRange,
} from "../../../utils/helpers";
import { exportNutritionMonth } from "./exportFunctions";
import { CALORIES } from "../../../../../constants";
import api from "../../../../../helper/axiosInstance";
import DatePickerCustom from "../../../../uiElements/DatePickerCustom";
import { getlastDayOfMonth } from "../../../utils/helpers";
const qualified = { color: "green" };
const unqualified = { color: "red" };

const NutritionMonthReport = ({ groups }) => {
  const [school, setSchool] = useState({});
  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [classGroups, setClassGroups] = useState(groups);

  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [termDate, setTermDate] = useState(new Date());
  let recommendArray = [
    {
      id: 1,
      text: "Cần tăng chất đạm trong các loại thịt cá cho",
      groups: [],
      isShow: false,
    },
    {
      id: 2,
      text: "Cần tăng chất béo trong thực phẩm cho",
      groups: [],
      isShow: false,
    },
    {
      id: 3,
      text: "Cần tăng chất đường trong các loại củ quả cho",
      groups: [],
      isShow: false,
    },
    {
      id: 4,
      text: "Cần giảm chất đạm trong các loại thịt cá cho",
      groups: [],
      isShow: false,
    },
    {
      id: 5,
      text: "Cần giảm chất béo trong thực phẩm cho",
      groups: [],
      isShow: false,
    },
    {
      id: 6,
      text: "Cần giảm chất đường trong các loại củ quả cho",
      groups: [],
      isShow: false,
    },
    {
      id: 7,
      text: "Cần tăng lượng thức ăn để đảm bảo năng lượng cho",
      groups: [],
      isShow: false,
    },
    {
      id: 8,
      text: "Năng lượng ăn trong trường quá cao, có thể gây béo phì cho",
      groups: [],
      isShow: false,
    },
  ];
  useEffect(() => {
    const date = new Date();
    const initialMonth = datesOfMonth(date.getMonth(), date.getFullYear());

    setStartDate(initialMonth[0]);
    setTermDate(initialMonth[initialMonth.length - 1]);

    getListQuantitatives(
      initialMonth[0],
      initialMonth[initialMonth.length - 1]
    );
    getDetailschool();
  }, []);

  // API
  const getListQuantitatives = async (startDate, endDate) => {
    setIsLoading(true);
    setListQuantitatives([]);
    await api
      .get(`nutrition-report`, {
        params: {
          start_date: parseInt(startDate.getTime() / 1000),
          end_date: parseInt(endDate.getTime() / 1000),
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setClassGroups(
            groups.filter((bItem) =>
              res.data.groups?.some((aItem) => aItem.id == bItem.id)
            ) ?? []
          );

          setListQuantitatives(res.data.days ?? []);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };
  // API

  // Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    const ws = exportNutritionMonth(classGroups.length, recommendArray);

    XLSX.utils.book_append_sheet(wb, ws, "Tổng hợp dinh dưỡng");
    XLSX.writeFile(
      wb,
      `Tổng hợp dinh dưỡng tháng ${
        startDate.getMonth() + 1
      } năm ${termDate.getFullYear()}.xlsx`
    );
  };
  const handleXportNutrionMonthReport = () => {
    swal({
      title: `Bạn muốn xuất file tổng hợp dinh dưỡng`,
      text: `Tháng ${startDate.getMonth() + 1} năm ${termDate.getFullYear()}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const datesOfMonth = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };
  // Logic

  // Render
  const NutritionMonthReport = () => {
    if (listQuantitatives.length === 0) {
      return "";
    }

    let bigIngredientArray = [];
    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.costPerDays.map((costPerDay) => {
        bigIngredientArray = bigIngredientArray.concat(costPerDay.ingredents);
        return costPerDay;
      });
    });

    const groupIngredientArray = groupArrayByField(bigIngredientArray, "id");

    return (
      <div style={{ overflowX: "scroll" }}>
        <div className="mb-3">
          <div>Năng lượng hiện tại: </div>

          {classGroups.map((groupItem) => {
            return (
              <div key={groupItem.id}>
                + {groupItem.group_name}: Cả ngày {groupItem.recommended_from} -{" "}
                {groupItem.recommended_to} kcal; Định mức 100% trong trường là{" "}
                <div className="d-inline fw-bold">
                  {Math.round(
                    Number(
                      (groupItem.recommended_from * groupItem.enegry_ratio) /
                        100
                    )
                  )}{" "}
                  kcal
                </div>{" "}
                (chiếm {groupItem.enegry_ratio}% cả ngày).
              </div>
            );
          })}
        </div>

        <Table bordered>
          <thead className="align-middle text-center">
            <tr className="text-start">
              <th colSpan={6}>{school.school_name}</th>
            </tr>
            <tr>
              <th colSpan={6}>
                BẢNG TỔNG HỢP DINH DƯỠNG THÁNG {startDate.getMonth() + 1} NĂM{" "}
                {termDate.getFullYear()}
              </th>
            </tr>
            <tr>
              <th>NHÓM</th>
              <th>NĂNG LƯỢNG CẢ NGÀY</th>
              <th>ĐẠM (P)</th>
              <th>BÉO (L)</th>
              <th>ĐƯỜNG BỘT (G)</th>
              <th>GHI CHÚ</th>
            </tr>
          </thead>
          <tbody className="align-middle">
            {classGroups.map((classGroupItem, index) => {
              const rangeQuantity = findeQuantitativeZone(
                classGroupItem.id_age_group
              );

              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );

              const energyRecommend = Math.round(
                Number(
                  (classGroupItem.recommended_from *
                    classGroupItem.enegry_ratio) /
                    100
                )
              );

              let energy = 0;
              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  energy +=
                    Math.round(
                      Number(
                        (gramOneChild * ingredientItem.ingredent_energy) / 100
                      ) * 100
                    ) / 100;
                  protein +=
                    Math.round(
                      Number(
                        (gramOneChild * ingredientItem.ingredent_protein) / 100
                      ) * 100
                    ) / 100;
                  lipid +=
                    Math.round(
                      Number(
                        (gramOneChild * ingredientItem.ingredent_lipid) / 100
                      ) * 100
                    ) / 100;
                  glucid +=
                    Math.round(
                      Number(
                        (gramOneChild * ingredientItem.ingredent_glucid) / 100
                      ) * 100
                    ) / 100;

                  return ingredientItem;
                });
              });

              const plgTotal =
                protein * CALORIES.PROTEIN +
                lipid * CALORIES.LIPID +
                glucid * CALORIES.GLUCID;

              const energyRatio = priceFormatter.format(
                Number(
                  (energy * 100) / listQuantitatives.length / energyRecommend
                )
              );
              const proteinRatio = priceFormatter.format(
                (protein * 400) / plgTotal
              );
              const lipidRatio = priceFormatter.format(
                (lipid * 900) / plgTotal
              );
              const glucidRatio = priceFormatter.format(
                100 - (Number(proteinRatio) + Number(lipidRatio))
              );

              if (isInRange(proteinRatio, "PROTEIN", rangeQuantity)) {
                if (proteinRatio < rangeQuantity?.PROTEIN?.MIN) {
                  recommendArray[0].groups.push({
                    id: classGroupItem.id,
                    name: classGroupItem.group_name,
                  });
                  recommendArray[0].isShow = true;
                } else if (proteinRatio > rangeQuantity?.PROTEIN?.MAX) {
                  recommendArray[3].groups.push({
                    id: classGroupItem.id,
                    name: classGroupItem.group_name,
                  });
                  recommendArray[3].isShow = true;
                }
              }

              if (isInRange(lipidRatio, "LIPID", rangeQuantity)) {
                if (lipidRatio < rangeQuantity?.LIPID?.MIN) {
                  recommendArray[1].groups.push({
                    id: classGroupItem.id,
                    name: classGroupItem.group_name,
                  });
                  recommendArray[1].isShow = true;
                } else if (lipidRatio > rangeQuantity?.LIPID?.MAX) {
                  recommendArray[4].groups.push({
                    id: classGroupItem.id,
                    name: classGroupItem.group_name,
                  });
                  recommendArray[4].isShow = true;
                }
              }

              if (
                isInRange(glucidRatio, "GLUCID", rangeQuantity)

                // glucidRatio < EAT_URBAN_AREAS.GLUCID
              ) {
                if (glucidRatio < rangeQuantity?.GLUCID?.MIN) {
                  recommendArray[2].groups.push({
                    id: classGroupItem.id,
                    name: classGroupItem.group_name,
                  });
                  recommendArray[2].isShow = true;
                } else if (glucidRatio > rangeQuantity?.GLUCID?.MAX) {
                  recommendArray[5].groups.push({
                    id: classGroupItem.id,
                    name: classGroupItem.group_name,
                  });
                  recommendArray[5].isShow = true;
                }
              }

              if (energyRatio < 100) {
                recommendArray[6].groups.push({
                  id: classGroupItem.id,
                  name: classGroupItem.group_name,
                });
                recommendArray[6].isShow = true;
              }

              if (energyRatio > 200) {
                recommendArray[7].groups.push({
                  id: classGroupItem.id,
                  name: classGroupItem.group_name,
                });
                recommendArray[7].isShow = true;
              }

              return (
                <React.Fragment key={classGroupItem.id}>
                  <tr className="text-center">
                    <td className="text-start">{classGroupItem.group_name}</td>
                    <td>
                      <CurrencyFormat
                        value={priceFormatter.format(
                          energy / listQuantitatives.length
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                    <td>
                      {priceFormatter.format(
                        protein / listQuantitatives.length
                      )}
                    </td>
                    <td>
                      {priceFormatter.format(lipid / listQuantitatives.length)}
                    </td>
                    <td>
                      {priceFormatter.format(glucid / listQuantitatives.length)}
                    </td>
                    <td></td>
                  </tr>
                  <tr className="text-center">
                    <td className="text-start">Tỷ lệ đạt %</td>
                    <td style={energyRatio < 100 ? unqualified : qualified}>
                      {" "}
                      {energyRatio == "NaN" ? "0" : energyRatio ?? 0}%
                    </td>
                    <td
                      style={
                        isInRange(proteinRatio, "PROTEIN", rangeQuantity)
                          ? // proteinRatio < EAT_URBAN_AREAS.PROTIT
                            unqualified
                          : qualified
                      }
                    >
                      {" "}
                      {proteinRatio == "NaN" ? "0" : proteinRatio ?? 0}%
                    </td>
                    <td
                      style={
                        isInRange(lipidRatio, "LIPID", rangeQuantity)
                          ? // lipidRatio < EAT_URBAN_AREAS.LIPID
                            unqualified
                          : qualified
                      }
                    >
                      {lipidRatio == "NaN" ? "0" : lipidRatio ?? 0}%
                    </td>
                    <td
                      style={
                        isInRange(glucidRatio, "GLUCID", rangeQuantity)
                          ? // glucidRatio < EAT_URBAN_AREAS.GLUCID
                            unqualified
                          : qualified
                      }
                    >
                      {glucidRatio == "NaN" ? "0" : glucidRatio ?? 0}%
                    </td>
                    <td className="fw-bold">
                      {energyRatio < 100 ? "Không đạt" : "Đạt"}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}

            <tr>
              <th colSpan={6}>Kết quả dinh dưỡng đạt được</th>
            </tr>

            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );
              const energyRecommend = Math.round(
                Number(
                  (classGroupItem.recommended_from *
                    classGroupItem.enegry_ratio) /
                    100
                )
              );

              let energy = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  energy += Number(
                    (gramOneChild * ingredientItem.ingredent_energy) / 100
                  );
                  return ingredientItem;
                });
              });

              const energyRatio = priceFormatter.format(
                Number(
                  (energy * 100) / listQuantitatives.length / energyRecommend
                )
              );

              return (
                <tr key={classGroupItem.id}>
                  <td colSpan={6}>
                    Năng lượng cả ngày {classGroupItem.group_name} đạt được{" "}
                    <div
                      style={energyRatio < 100 ? unqualified : qualified}
                      className="d-inline"
                    >
                      {priceFormatter.format(energyRatio)}%
                    </div>
                  </td>
                </tr>
              );
            })}

            {classGroups.map((classGroupItem) => {
              const rangeQuantity = findeQuantitativeZone();
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );

              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  protein += Number(
                    (gramOneChild * ingredientItem.ingredent_protein) / 100
                  );
                  lipid += Number(
                    (gramOneChild * ingredientItem.ingredent_lipid) / 100
                  );
                  glucid += Number(
                    (gramOneChild * ingredientItem.ingredent_glucid) / 100
                  );

                  return ingredientItem;
                });
              });

              const plgTotal = protein * 4 + lipid * 9 + glucid * 4;
              const proteinRatio = priceFormatter.format(
                (protein * 400) / plgTotal
              );

              return (
                <tr key={classGroupItem.id}>
                  <td colSpan={6}>
                    Chất đạm của {classGroupItem.group_name}{" "}
                    <div
                      style={
                        isInRange(
                          proteinRatio,
                          "PROTEIN",
                          findeQuantitativeZone(classGroupItem.id_age_group)
                        )
                          ? // proteinRatio < 14
                            unqualified
                          : qualified
                      }
                      className="d-inline"
                    >
                      {proteinRatio == "NaN"
                        ? "0"
                        : priceFormatter.format(proteinRatio ?? 0)}
                      %{/* {priceFormatter.format(proteinRatio??0)}% */}
                    </div>
                  </td>
                </tr>
              );
            })}

            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );

              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  protein += Number(
                    (gramOneChild * ingredientItem.ingredent_protein) / 100
                  );
                  lipid += Number(
                    (gramOneChild * ingredientItem.ingredent_lipid) / 100
                  );
                  glucid += Number(
                    (gramOneChild * ingredientItem.ingredent_glucid) / 100
                  );

                  return ingredientItem;
                });
              });

              const plgTotal =
                protein * CALORIES.PROTEIN +
                lipid * CALORIES.LIPID +
                glucid * CALORIES.GLUCID;
              const lipidRatio = priceFormatter.format(
                (lipid * 900) / plgTotal
              );

              return (
                <tr key={classGroupItem.id}>
                  <td colSpan={6}>
                    Chất béo của {classGroupItem.group_name} đạt được{" "}
                    <div
                      style={
                        isInRange(
                          lipidRatio,
                          "LIPID",
                          findeQuantitativeZone(classGroupItem.id_age_group)
                        )
                          ? // lipidRatio < EAT_URBAN_AREAS.LIPID
                            unqualified
                          : qualified
                      }
                      className="d-inline"
                    >
                      {lipidRatio == "NaN"
                        ? "0"
                        : priceFormatter.format(lipidRatio ?? 0)}
                      %{/* {priceFormatter.format(lipidRatio??0)}% */}
                    </div>
                  </td>
                </tr>
              );
            })}

            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );

              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  protein += Number(
                    (gramOneChild * ingredientItem.ingredent_protein) / 100
                  );
                  lipid += Number(
                    (gramOneChild * ingredientItem.ingredent_lipid) / 100
                  );
                  glucid += Number(
                    (gramOneChild * ingredientItem.ingredent_glucid) / 100
                  );

                  return ingredientItem;
                });
              });

              const plgTotal = protein * 4 + lipid * 9 + glucid * 4;

              const proteinRatio = priceFormatter.format(
                (protein * 400) / plgTotal
              );
              const lipidRatio = priceFormatter.format(
                (lipid * 900) / plgTotal
              );
              const glucidRatio = priceFormatter.format(
                100 - (Number(proteinRatio) + Number(lipidRatio))
              );

              return (
                <tr key={classGroupItem.id}>
                  <td colSpan={6}>
                    Chất đường của {classGroupItem.group_name} đạt{" "}
                    <div
                      style={
                        isInRange(
                          glucidRatio,
                          "GLUCID",
                          findeQuantitativeZone(classGroupItem.id_age_group)
                        )
                          ? // glucidRatio < 60

                            unqualified
                          : qualified
                      }
                      className="d-inline"
                    >
                      {glucidRatio == "NaN"
                        ? "0"
                        : priceFormatter.format(glucidRatio ?? 0)}
                      %
                    </div>
                  </td>
                </tr>
              );
            })}

            <tr>
              <td colSpan={6}>
                {recommendArray.map((recommendItem) => {
                  if (!recommendItem.isShow) {
                    return "";
                  }

                  return (
                    <div key={recommendItem.id}>
                      {recommendItem.text}{" "}
                      {recommendItem.groups.map((groupItem, groupIndex) => {
                        if (recommendItem.groups.length - 1 === groupIndex) {
                          return `${groupItem.name}.`;
                        }

                        return `${groupItem.name}, `;
                      })}
                    </div>
                  );
                })}
              </td>
            </tr>
          </tbody>
          <tfoot className="text-center align-middle">
            <tr>
              <th colSpan={3}></th>
              <th colSpan={3}>
                Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1}{" "}
                năm {new Date().getFullYear()}
              </th>
            </tr>
            <tr>
              <th colSpan={3}>NGƯỜI LẬP BẢNG</th>
              <th colSpan={3}>HIỆU TRƯỞNG</th>
            </tr>
          </tfoot>
        </Table>

        <Table bordered id="nutrition-month-report" className="d-none">
          <thead className="align-middle text-center">
            <tr className="text-start">
              <th>{school.school_name}</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>
                BẢNG TỔNG HỢP DINH DƯỠNG THÁNG {startDate.getMonth() + 1} NĂM{" "}
                {termDate.getFullYear()}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>NHÓM</th>
              <th>NĂNG LƯỢNG CẢ NGÀY</th>
              <th>ĐẠM (P)</th>
              <th>BÉO (L)</th>
              <th>ĐƯỜNG BỘT (G)</th>
              <th>GHI CHÚ</th>
            </tr>
          </thead>
          <tbody className="align-middle">
            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );
              const energyRecommend = Math.round(
                Number(
                  (classGroupItem.recommended_from *
                    classGroupItem.enegry_ratio) /
                    100
                )
              );

              let energy = 0;
              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  energy += Number(
                    (gramOneChild * ingredientItem.ingredent_energy) / 100
                  );
                  protein += Number(
                    (gramOneChild * ingredientItem.ingredent_protein) / 100
                  );
                  lipid += Number(
                    (gramOneChild * ingredientItem.ingredent_lipid) / 100
                  );
                  glucid += Number(
                    (gramOneChild * ingredientItem.ingredent_glucid) / 100
                  );

                  return ingredientItem;
                });
              });

              const plgTotal = protein * 4 + lipid * 9 + glucid * 4;

              const energyRatio = priceFormatter.format(
                Number(
                  (energy * 100) / listQuantitatives.length / energyRecommend
                )
              );
              const proteinRatio = priceFormatter.format(
                (protein * 400) / plgTotal
              );
              const lipidRatio = priceFormatter.format(
                (lipid * 900) / plgTotal
              );
              const glucidRatio = priceFormatter.format(
                (glucid * 400) / plgTotal
              );

              return (
                <React.Fragment key={classGroupItem.id}>
                  <tr className="text-center">
                    <td className="text-start">{classGroupItem.group_name}</td>
                    <td>
                      <CurrencyFormat
                        value={Math.round(energy / listQuantitatives.length)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>

                    <td>
                      {priceFormatter.format(
                        (energy / listQuantitatives.length) *
                          (protein / plgTotal)
                      ) == "NaN"
                        ? 0
                        : priceFormatter.format(
                            (energy / listQuantitatives.length) *
                              (protein / plgTotal)
                          )}
                    </td>
                    <td>
                      {priceFormatter.format(
                        (energy / listQuantitatives.length) * (lipid / plgTotal)
                      ) == "NaN"
                        ? 0
                        : priceFormatter.format(
                            (energy / listQuantitatives.length) *
                              (lipid / plgTotal)
                          )}
                    </td>
                    <td>
                      {priceFormatter.format(
                        (energy / listQuantitatives.length) *
                          (glucid / plgTotal)
                      ) == "NaN"
                        ? 0
                        : priceFormatter.format(
                            (energy / listQuantitatives.length) *
                              (glucid / plgTotal)
                          )}
                    </td>
                    <td>empty</td>
                  </tr>
                  <tr className="text-center">
                    <td className="text-start">Tỷ lệ đạt %</td>
                    <td>{energyRatio == "NaN" ? 0 : energyRatio}%</td>
                    <td>{proteinRatio == "NaN" ? 0 : proteinRatio}%</td>
                    <td>{lipidRatio == "NaN" ? 0 : lipidRatio}%</td>
                    <td>{glucidRatio == "NaN" ? 0 : glucidRatio}%</td>
                    <td>
                      {priceFormatter.format(
                        Number(
                          (energy * 100) /
                            listQuantitatives.length /
                            energyRecommend
                        )
                      ) < 100
                        ? "Không đạt"
                        : "Đạt"}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}

            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>

            <tr>
              <th>Kết quả dinh dưỡng đạt được</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>

            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );
              const energyRecommend = Math.round(
                Number(
                  (classGroupItem.recommended_from *
                    classGroupItem.enegry_ratio) /
                    100
                )
              );

              let energy = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  energy += Number(
                    (gramOneChild * ingredientItem.ingredent_energy) / 100
                  );
                  return ingredientItem;
                });
              });

              return (
                <tr key={classGroupItem.id}>
                  <td>
                    Năng lượng cả ngày {classGroupItem.group_name} đạt được{" "}
                    {priceFormatter.format(
                      Number(
                        (energy * 100) /
                          listQuantitatives.length /
                          energyRecommend
                      )
                    )}
                    %
                  </td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            })}

            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );

              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  protein += Number(
                    (gramOneChild * ingredientItem.ingredent_protein) / 100
                  );
                  lipid += Number(
                    (gramOneChild * ingredientItem.ingredent_lipid) / 100
                  );
                  glucid += Number(
                    (gramOneChild * ingredientItem.ingredent_glucid) / 100
                  );

                  return ingredientItem;
                });
              });

              const plgTotal = protein * 4 + lipid * 9 + glucid * 4;

              return (
                <tr key={classGroupItem.id}>
                  <td>
                    Chất đạm của {classGroupItem.group_name}{" "}
                    {priceFormatter.format((protein * 400) / plgTotal) == "NaN"
                      ? "0"
                      : priceFormatter.format((protein * 400) / plgTotal) ?? 0}
                    %
                    {/* {priceFormatter.format((protein * 400) / plgTotal)}% */}
                  </td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            })}

            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );

              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  protein += Number(
                    (gramOneChild * ingredientItem.ingredent_protein) / 100
                  );
                  lipid += Number(
                    (gramOneChild * ingredientItem.ingredent_lipid) / 100
                  );
                  glucid += Number(
                    (gramOneChild * ingredientItem.ingredent_glucid) / 100
                  );

                  return ingredientItem;
                });
              });

              const plgTotal = protein * 4 + lipid * 9 + glucid * 4;

              return (
                <tr key={classGroupItem.id}>
                  <td>
                    Chất béo của {classGroupItem.group_name} đạt được{" "}
                    {priceFormatter.format((lipid * 900) / plgTotal) == "NaN"
                      ? "0"
                      : priceFormatter.format((lipid * 900) / plgTotal) ?? 0}
                    %
                  </td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            })}

            {classGroups.map((classGroupItem) => {
              const classGroupIngredientArray = groupIngredientArray.map(
                (groupIngredientItem) =>
                  groupIngredientItem.filter(
                    (ingredientItem) =>
                      ingredientItem.id_group === classGroupItem.id
                  )
              );

              let protein = 0;
              let lipid = 0;
              let glucid = 0;

              classGroupIngredientArray.map((groupIngredientItem) => {
                return groupIngredientItem.map((ingredientItem) => {
                  const gramOneChild = Number(
                    ((ingredientItem.ing_quantity *
                      ingredientItem.volume_market) /
                      ingredientItem.volume_cook) *
                      ((100 - ingredientItem.ingredent_ratio) / 100)
                  );

                  protein += Number(
                    (gramOneChild * ingredientItem.ingredent_protein) / 100
                  );
                  lipid += Number(
                    (gramOneChild * ingredientItem.ingredent_lipid) / 100
                  );
                  glucid += Number(
                    (gramOneChild * ingredientItem.ingredent_glucid) / 100
                  );

                  return ingredientItem;
                });
              });

              const plgTotal = protein * 4 + lipid * 9 + glucid * 4;

              return (
                <tr key={classGroupItem.id}>
                  <td>
                    Chất đường của {classGroupItem.group_name} đạt{" "}
                    {priceFormatter.format((glucid * 400) / plgTotal) == "NaN"
                      ? "0"
                      : priceFormatter.format((glucid * 400) / plgTotal) ?? 0}
                    %
                  </td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            })}

            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>

            <tr>
              <td>
                {recommendArray.map((recommendItem) => {
                  if (!recommendItem.isShow) {
                    return "";
                  }

                  return (
                    <div key={recommendItem.id}>
                      {recommendItem.text}{" "}
                      {recommendItem.groups.map((groupItem, groupIndex) => {
                        if (recommendItem.groups.length - 1 === groupIndex) {
                          return `${groupItem.name}.`;
                        }

                        return `${groupItem.name} + `;
                      })}
                      <br />
                    </div>
                  );
                })}
              </td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
          </tbody>
          <tfoot className="text-center align-middle">
            <tr>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>

            <tr>
              <th></th>
              <th>empty</th>
              <th>empty</th>
              <th>
                Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1}{" "}
                năm {new Date().getFullYear()}
              </th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>NGƯỜI LẬP BẢNG</th>
              <th>empty</th>
              <th>empty</th>
              <th>HIỆU TRƯỞNG</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex">
              <Card.Title className="me-3 d-flex align-items-center">
                Tổng hợp dinh dưỡng tháng {startDate.getMonth() + 1} năm{" "}
                {termDate.getFullYear()}
              </Card.Title>
              <div className="d-flex align-items-center">
                <div
                  className="me-2 d-flex align-items-center"
                  style={{ zIndex: "1021" }}
                >
                  <DatePickerCustom
                    disabled={isLoading}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setTermDate(
                        getlastDayOfMonth(date?.getFullYear(), date?.getMonth())
                      );
                      getListQuantitatives(
                        date,
                        getlastDayOfMonth(date?.getFullYear(), date?.getMonth())
                      );
                    }}
                    showMonthYearPicker
                  />
                </div>
              </div>
            </div>
            <Button
              size="sm"
              variant="success"
              onClick={() => handleXportNutrionMonthReport()}
              className="mt-3 mt-md-0 me-2"
              disabled={isLoading || listQuantitatives.length === 0}
            >
              Xuất file <i className="fa-solid fa-file-export" />
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : listQuantitatives.length !== 0 ? (
            <Row>
              <div className="mb-3">
                <div>
                  Nhu cầu khuyến nghị năng lượng tại cơ sở giáo dục mầm
                  non/ngày/trẻ theo thông tư số 28/2016/TT-BGDĐT ngày 30 tháng
                  12 năm 2016
                </div>
                <div>
                  + Nhà trẻ: Cả ngày 930 - 1000 kcal; Định mức 100% trong trường
                  là <div className="d-inline fw-bold">605 kcal</div> (chiếm 60
                  - 70% cả ngày).
                </div>
                <div>
                  + Mẫu giáo: Cả ngày 1230 - 1320 kcal; Định mức 100% trong
                  trường là <div className="d-inline fw-bold">615 kcal</div>{" "}
                  (chiếm 50 - 55% cả ngày).
                </div>
              </div>

              {NutritionMonthReport()}
            </Row>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NutritionMonthReport;
