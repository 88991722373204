import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { startOfWeek, endOfWeek } from "date-fns";
import CheckboxTree from "react-checkbox-tree";

import api from "../../../helper/axiosInstance";
import DatePickerCustom from "../../uiElements/DatePickerCustom";
import TableRegister from "./TableRegister";

class RegisterMealIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menus: [],
      checkload: true,
      startDate: new Date(),
      endDate: new Date(),
      listClass: [],
      choiceClass: { value: 0, label: "Tất cả lớp" },
      schoolInfo: {},
      modalRegister: false,
      modalApprove: false,
      arrClassChoice: [],
      arrDay: [],
      menuRegister: [],
      listDaySetting: [],
    };
  }

  componentDidMount() {
    document.title = "Đăng ký suất ăn";
    this.getDetailschool();
    this.setState({
      startDate: new Date(
        startOfWeek(new Date(), { weekStartsOn: 1 }).setHours(0, 0, 0)
      ),
      endDate: new Date(
        endOfWeek(new Date(), { weekStartsOn: 1 }).setHours(23, 59, 59)
      ),
    });
    this.getClassRoom(
      new Date(startOfWeek(new Date(), { weekStartsOn: 1 }).setHours(0, 0, 0)) /
        1000,
      new Date(
        endOfWeek(new Date(), { weekStartsOn: 1 }).setHours(23, 59, 59)
      ) / 1000
    );
  }
  stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  getDetailschool = () => {
    api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            schoolInfo: res.data.data[0],
          });
        }
      })
      .catch((err) => {});
  };

  getClassRoom = (start, end) => {
    api
      .get(`/classroom-shows?start_date=${parseInt(start)}&end_date=${end}`)
      .then((res) => {
        let arr = [];
        res.data.data?.classrooms?.map((item) => {
          return arr.push({
            value: item.id,
            label: item.class_name,
            id_group: item.id_group,
            group_name: item.group_name,
          });
        });

        this.setState({ listClass: arr, choiceClass: arr[0] });
        this.getRegister(start * 1000, end * 1000, arr[0]);
      })
      .catch((err) => {});
  };
  getNumberOfPortions(arr, id_day, id_time, id_food) {
    const dayItem = arr?.find(
      (item) => Number(item.day?.id) === Number(id_day)
    );
    if (!dayItem) return "";

    const timeItem = dayItem?.times?.find(
      (time) => Number(time.time.id) === Number(id_time)
    );
    if (!timeItem) return "";

    const foodItem = timeItem?.foods?.find(
      (food) => Number(food.id) === Number(id_food)
    );
    if (!foodItem) return "";

    return foodItem.number_of_portions || "";
  }

  getMaxNumberOfPortions(arr, id_day) {
    const menuForDay = arr.find((menu) => menu.day.id === id_day);

    if (!menuForDay) {
      return 0;
    }

    const foods = menuForDay?.times[0]?.foods;
    if (!foods || foods.length === 0) {
      return 0;
    }

    const firstCategoryId = foods[0].id_category;
    return foods
      .filter((food) => food.id_category === firstCategoryId)
      .reduce((total, food) => total + food.number_of_portions, 0);
  }

  getMenu = (arrRegister, start, end, classChoice) => {
    api
      .get(
        `/get-menu-by-group?term_at=${parseInt(end / 1000)}&start_at=${parseInt(
          start / 1000
        )}&id_group=${classChoice?.id_group}`
      )
      .then((res) => {
        this.setState({
          checkload: false,
          // choiceClass: this.state.choiceClass,
        });

        let arr = {
          ...res.data.data,
          menu_has_days: res.data.data?.menu_has_days?.map((item) => ({
            ...item,
            totalQuantity: this.getMaxNumberOfPortions(
              arrRegister,
              item.id_day
            ),
            menu_has_times: item.menu_has_times?.map((e) => ({
              ...e,
              foods: e.foods?.map((f) => ({
                ...f,
                id_day: item.id_day,
                quantity: this.getNumberOfPortions(
                  arrRegister,
                  item.id_day,
                  e.id_time,
                  f.id
                ),
                totalQuantity: this.getMaxNumberOfPortions(
                  arrRegister,
                  item.id_day
                ),
                totalCategory: e.foods?.filter(
                  (food) => food.id_category === f.id_category
                )?.length,
                id_time: e.id_time,
              })),
            })),
          })),
        };
        this.setState({ menus: arr.menu_has_days || [] });
      })
      .catch((err) => {});
  };

  getSetting = (start, end) => {
    api
      .get(
        `/get-setting-by-date-period?end_date=${parseInt(
          end / 1000
        )}&start_date=${parseInt(start / 1000)}`
      )
      .then((res) => {
        this.setState({
          checkload: false,
        });
        let arr = [];
        res.data.data?.map((item) => arr?.push(item.days));
        let result = [
          ...new Map(arr.flat().map((day) => [day.day_name, day])).values(),
        ];

        this.setState({ listDaySetting: result || [] });
      })
      .catch((err) => {
        toast(`Lỗi `, {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  getRegister = (start, end, classChoice) => {
    this.getSetting(start, end);
    api
      .get(
        `/get-registration-list-of-meals-by-class?end_at=${parseInt(
          end / 1000
        )}&start_at=${parseInt(start / 1000)}&id_class=${classChoice?.value}`
      )
      .then((res) => {
        this.setState({
          checkload: false,
          // choiceClass: this.state.choiceClass,
        });
        this.setState({ menuRegister: res.data.data || [] }, () => {
          this.getMenu(this.state.menuRegister, start, end, classChoice);
        });
      })
      .catch((err) => {
        toast(`Lỗi `, {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  submitRegister = () => {
    let data = {
      id_class: this.state.choiceClass?.value,
      id_menu: this.state.menus[0]?.id_menu,
      mealsPerDay: this.transformData(this.state.menus),
      register_by: Number(localStorage?.getItem("auth_id_user")) ?? null,
    };

    let arr = this.state.menus

      ?.filter((dayItem) =>
        this.state.arrDay?.some(
          (time) => Number(time) === Number(dayItem.id_day)
        )
      )
      ?.map((dayItem) => ({
        id_day: dayItem.id_day,
        day_name: dayItem.day_of_week?.day_name,
        meals: dayItem.menu_has_times.map((timeItem) => ({
          id_time: timeItem.id_time,
          name_time: timeItem.time_of_day.time_name_def,
          // time_name: timeItem.time_name,
          category: timeItem.foods.reduce((acc, food) => {
            const existingCategory = acc.find(
              (c) => c.id_Category === food.id_category
            );

            if (existingCategory) {
              existingCategory.totalCurrent += Number(food.quantity);
            } else {
              acc.push({
                id_Category: food.id_category,
                category_name: food.category.category_name,
                totalCurrent: Number(food.quantity),
                totalQuantity: Number(food.totalQuantity),
              });
            }

            return acc;
          }, []),
          foods: timeItem.foods.map((foodItem) => ({
            id_food: foodItem.id,
            name: foodItem.food_name,
            portions: parseInt(foodItem.quantity) || 0,
            id_category: foodItem.id_category,
            totalQuantity: foodItem.totalQuantity,
            totalCurrentQuantity: timeItem.foods
              ?.filter((e) => e.id_category === foodItem.id_category)
              ?.reduce((sum, food) => sum + Number(food.quantity), 0),
          })),
        })),
      }));
    let hasError = false;
    arr?.forEach((day) => {
      day.meals.forEach((meal) => {
        meal.category.forEach((cate) => {
          if (cate.totalCurrent > cate?.totalQuantity) {
            hasError = true;
            toast(
              `Tổng số lượng  món ăn danh mục ${cate.category_name} (buổi ${meal.name_time} thứ ${day?.day_name})  không được vượt quá ${cate.totalQuantity}`,
              {
                type: "error",
                autoClose: 2000,
              }
            );
          } else if (cate.totalCurrent < cate?.totalQuantity) {
            hasError = true;
            toast(
              `Tổng số lượng  món ăn danh mục ${cate.category_name} (buổi ${meal.name_time} thứ ${day?.day_name}) không được nhỏ hơn ${cate.totalQuantity}`,
              {
                type: "error",
                autoClose: 2000,
              }
            );
          }
        });
      });
    });

    if (!hasError)
      api
        .post(`meal-registration-create-or-update`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          toast(res.data.messages || "Thành công", { type: "success" });
          this.setState({ modalRegister: false, arrDay: [] });

          this.getRegister(
            this.state.startDate,
            this.state.endDate,
            this.state.choiceClass
          );
        })
        .catch((error) => {
          let isErr = false;
          this.setState({ errors: error.response.data.messages });
          Object.keys(error.response.data.messages).forEach((key) => {
            toast.error(error.response.data.messages[key]);
            isErr = true;
          });
          if (!isErr)
            toast(error.response.data.messages?.id_class || `Lỗi `, {
              type: "error",
              autoClose: 1000,
            });
        });
  };
  submitApprove = () => {
    let data = {
      id_class: this.state.arrClassChoice,
      id_menu: this.state.menus[0]?.id_menu,
      id_day: this.state.arrDay[0],
      status: "approved",
    };

    api
      .put(`update-status-registration-list-of-meals-by-class-and-day`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thành công", { type: "success" });
        this.setState({ modalApprove: false, arrDay: [], arrClassChoice: [] });

        this.getRegister(
          this.state.startDate,
          this.state.endDate,
          this.state.choiceClass
        );
      })
      .catch((error) => {
        toast(`Lỗi `, {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  transformData = (arr) => {
    return arr
      ?.filter((dayItem) =>
        this.state.arrDay?.some(
          (time) => Number(time) === Number(dayItem.id_day)
        )
      )
      ?.map((dayItem) => ({
        id_day: dayItem.id_day,
        meals: dayItem.menu_has_times.map((timeItem) => ({
          id_time: timeItem.id_time,
          foods: timeItem.foods.map((foodItem) => ({
            id_food: foodItem.id,
            portions: parseInt(foodItem.quantity) || 0,
          })),
        })),
      }));
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  setMenus = (updatedMenus) => {
    this.setState({ menus: updatedMenus });
  };

  dataTable() {
    const uniqueTimesid = this.state.menus
      ?.flatMap((item) =>
        item.menu_has_times.map((time) => ({
          id_time: time.time_of_day.id,
          time_name: time.time_of_day.time_name_def,
          // totalQuantity: time.totalQuantity,
        }))
      )
      .reduce((acc, current) => {
        if (!acc.some((time) => time.id_time === current.id_time)) {
          acc.push(current);
        }
        return acc;
      }, []);

    const uniqueCategories = [
      ...new Set(
        this.state.menus?.flatMap((item) =>
          item.menu_has_times.flatMap((time) =>
            time.foods.map((food) => food.category.category_name)
          )
        )
      ),
    ];

    return (
      <TableRegister
        listQuantitatives={this.state.menus}
        setMenus={this.setMenus}
        listDays={this.state.menus || []}
        listTimes={uniqueTimesid || []}
        choiceClass={this.state.choiceClass}
      />
    );
  }
  getOption = (data, type, checkAll) => {
    var arr_ = [];
    data.map((item, index) =>
      arr_.push({
        value: item.id_day || item.value || item.id,
        label: item.day_of_week?.day_name || item.label || item.day_name,
      })
    );
    const nodes = [
      {
        value: "0",
        label: "Tất cả",

        children: arr_,
      },
    ];
    return (
      <>
        <CheckboxTree
          nodes={checkAll ? nodes : arr_}
          showExpandAll
          checked={
            type === "class" ? this.state.arrClassChoice : this.state.arrDay
          }
          expanded={[0]}
          // onCheck={(checked) => {
          //   type === "class"
          //     ? this.setState({
          //         arrClassChoice: checked,
          //       })
          //     : this.setState({
          //         arrDay: checked,
          //       });
          // }}
          onCheck={(checked) => {
            if (!checkAll) {
              this.setState({
                arrDay: [],
              });
            }
            const newChecked = checkAll
              ? checked
              : [checked[checked.length - 1]];
            this.setState((prevState) => ({
              arrClassChoice:
                type === "class" ? newChecked : prevState.arrClassChoice,
              arrDay: type === "class" ? prevState.arrDay : newChecked,
            }));
          }}
          icons={{
            check: (
              <i
                className="fa-solid fa-square-check"
                style={{ color: "#005eff" }}
              ></i>
            ),
            uncheck: (
              <i
                className="fa-regular fa-square"
                style={{ color: "#005eff" }}
              ></i>
            ),
            halfCheck: (
              <i
                className="fa-regular fa-square "
                style={{ color: "#99beff" }}
              ></i>
            ),
            expandClose: "",
            expandOpen: "",
            parentClose: "",
            parentOpen: "",
            leaf: "",
          }}
        />
      </>
    );
  };
  hideModal = () => {
    this.setState({
      modalApprove: false,
      modalRegister: false,
      arrClassChoice: [],
      arrDay: [],
    });
  };
  renderRegisterList() {
    return (
      <>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex">
              <Card.Title className="me-3 d-flex align-items-center">
                <i className="fas fa-list me-1"></i> Đăng ký suất ăn{" "}
                {this.stringFirstDate(this.state.startDate)} -{" "}
                {this.stringLastDate(this.state.endDate)}{" "}
                {/* {this.state.choiceClass?.group_name} */}
                <div
                  className="d-flex mb-2 mb-md-0 align-items-center "
                  style={{ zIndex: 3 }}
                >
                  <div className="me-2 d-flex align-items-center mx-2">
                    <DatePickerCustom
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={(date) => {
                        const startDateGMT = startOfWeek(date, {
                          weekStartsOn: 1,
                        });
                        const endDateGMT = startOfWeek(
                          endOfWeek(date, { weekStartsOn: 1 })
                        );

                        this.setState({
                          startDate: startDateGMT,
                          endDate: endDateGMT,
                        });
                        // this.getMenu(
                        //   this.state.menuRegister,
                        //   startDateGMT,
                        //   endDateGMT,
                        //   this.state.choiceClass
                        // );
                        this.getRegister(
                          startDateGMT,
                          endDateGMT,
                          this.state.choiceClass
                        );
                      }}
                      showWeekNumbers
                      dateFormat="yyyy-MM-dd"
                      calendarClassName="custom-calendar"
                    />
                  </div>

                  <Select
                    options={this.state.listClass}
                    value={this.state.choiceClass}
                    onChange={(choice) => {
                      this.setState(
                        {
                          choiceClass: choice,
                        },
                        () => {
                          // this.getMenu(
                          //   this.state.menuRegister,
                          //   this.state.startDate,
                          //   this.state.endDate,
                          //   this.state.choiceClass
                          // );
                          this.getRegister(
                            this.state.startDate,
                            this.state.endDate,
                            this.state.choiceClass
                          );
                        }
                      );
                    }}
                  ></Select>
                </div>
              </Card.Title>

              <div className="d-flex align-items-center">
                <Button
                  variant="success mx-1"
                  onClick={() => this.setState({ modalRegister: true })}
                >
                  {" "}
                  Đăng ký
                </Button>
                <Button
                  variant="info mx-1"
                  onClick={() => this.setState({ modalApprove: true })}
                >
                  Duyệt
                </Button>
              </div>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          {this.state.checkload ? (
            this.loading()
          ) : this.state.menus ? (
            this.dataTable()
          ) : (
            <p className="text-center">Không có dữ liệu. . .</p>
          )}
        </Card.Body>
        <Modal show={this.state.modalRegister} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Đăng ký suất ăn {this.state.choiceClass?.label}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Chọn ngày </p>
            {this.getOption(this.state.menus, "day", true)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success mx-1"
              onClick={() => {
                if (this.state.arrDay?.length < 1) {
                  toast(`Vui lòng chọn ít nhất 1 ngày`, {
                    type: "error",
                    autoClose: 1000,
                  });
                } else {
                  this.submitRegister();
                }
              }}
            >
              Đăng ký
            </Button>

            <Button variant="secondary" onClick={() => this.hideModal()}>
              Hủy
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.modalApprove} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Duyệt đăng ký suất ăn</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <p>Chọn ngày: </p>
                {this.getOption(this.state.listDaySetting, "day", false)}
              </Col>
              <Col>
                <p>Chọn lớp: </p>
                {this.getOption(this.state.listClass, "class", true)}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success mx-1"
              onClick={() => {
                if (this.state.arrClassChoice?.length < 1) {
                  toast(`Vui lòng chọn ít nhất 1 lớp`, {
                    type: "error",
                    autoClose: 1000,
                  });
                } else {
                  if (this.state.arrDay?.length < 1) {
                    toast(`Vui lòng chọn ít nhất 1 ngày`, {
                      type: "error",
                      autoClose: 1000,
                    });
                  } else {
                    this.submitApprove();
                  }
                }
              }}
            >
              Duyệt
            </Button>

            <Button variant="secondary" onClick={() => this.hideModal()}>
              Hủy
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 ">
            <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
            <Breadcrumb.Item active>Đăng ký suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRegisterList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default RegisterMealIndex;
