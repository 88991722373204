import React, { Component } from "react";
import {
  Button,
  Form,
  Modal,
  Col,
  Row,
  Tabs,
  Tab,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import CheckboxTree from "react-checkbox-tree";
import moment from "moment/moment";
import Select from "react-select";
import { dateFormatter, getTime } from "../utils/helpers";
import { capitalizeEachWord } from "../../../helper/texts";
import NotEatTimeAddModal from "./NotEatTimeAddModal";
import NotEatTimeEditModal from "./NotEatTimeEditModal";
import { formatTimestamp } from "../../../helper/times";
import MedicModal from "../medication/MedicModal";
import MedicEditModal from "../medication/MedicEditModal";
import { toastError } from "../../../helper/messages";
import ClassHistoryEditModal from "./ClassHistoryEditModal";
import StudentMealEditModal from "./StudentMealEditModal";
import Check from "../other/Check";
import { IS_ACTIVE } from "../../../constants";
import api from "../../../helper/axiosInstance";
import Achievement from "./Achievement";
import Reward from "./Reward";

class StudentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      dataInput: props.data,
      btnClick: false,
      show: false,
      type: "",
      listclass: props.classrooms,
      tabkey: 1,
      listEthnic: [],
      abledForm: props?.abledForm?.value,
      abledFormPause: !props?.abledForm?.value,
      listTimes: [],
      not_eats: [],
      err: {},
      noIngredent: [],
      showAddModalNotEat: false,
      showEditModalNotEat: false,
      notEatTime: {},
      medication: [],
      showAddModalMedic: false,
      itemMedic: {},
      showMedicModal: false,

      showClassHistoryModal: false,
      classHasStudent: {},
      startDate: new Date(),
      endDate: new Date(),
      showStudentMealModal: false,
      studentMeal: {},
    };
  }
  async componentDidMount() {
    this.setState(
      {
        startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
        endDate: this.lastDayy(
          new Date().getFullYear(),
          Number(new Date().getMonth()) + 1
        ),
      },
      () => this.getMedic(this.state.dataInput?.id)
    );
    this.getEthnic();
    this.setState({ btnClick: false });
    this.getTimeOfDay();
    let arr = [];

    this.props?.noIngredent?.map((item) => {
      arr?.push({
        value: item.id_ingredient,
        label: this.props.ingredents?.filter(
          (e) => Number(e.value) === Number(item.id_ingredient)
        )[0]?.label,
      });

      return item;
    });

    let no_ingredient = {
      note: this.props?.noIngredent[0]?.notes,
      list_ingredient: arr,
    };
    this.setState({ noIngredent: no_ingredient });

    if (this.state?.dataInput?.student_father == null) {
      this.setState({
        dataInput: {
          ...this.state?.dataInput,
          student_father: {
            name: "",
            job: "",
            phone: "",
          },
        },
      });
    }
    if (this.state?.dataInput?.student_mother == null) {
      this.setState({
        dataInput: {
          ...this.state?.dataInput,
          student_mother: {
            name: "",
            job: "",
            phone: "",
          },
        },
      });
    }
    if (this.state?.dataInput?.student_address == null) {
      this.setState({
        dataInput: {
          ...this.state?.dataInput,
          student_address: {
            number: "",
            group: "",
            zone: "",
            street: "",
            ward: "",
            district: "",
            city: "",
          },
        },
      });
    }
  }

  getMedic = (id) => {
    api
      .get(
        `/medication-reminder-shows?studentId=${id}&startDate=${parseInt(
          this.state.startDate / 1000
        )}&endDate=${parseInt(this.state.endDate / 1000)}`
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({ medication: res.data.data?.docs });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  getTimeOfDay = () => {
    api
      .get("/getTimeOfDays")

      .then((res) => this.setState({ listTimes: res.data.times }));
  };

  getEthnic = () => {
    api
      .get("/viewEthnic")
      .then((res) => this.setState({ listEthnic: res.data.data }))
      .catch((err) => {});
  };

  getlistclass = () => {
    api
      .get(`/classroom-shows?id_year=${this.props?.idYearNow}`)
      .then((res) => {
        this.setState({
          listclass: res.data?.data?.classrooms,
        });
        this.setState({ abledForm: false, abledFormPause: true });
      })
      .catch((error) => {});
  };

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    if (event.target.name === "student_birthday") {
      value = new Date(event.target.value) / 1000;
    }

    if (field === "student_name") value = capitalizeEachWord(value);

    let dataInput = { ...this.state.dataInput };

    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };
  handleInputaddress = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let studentAddress = prevState.dataInput.student_address;

      // Check if studentAddress is a string and parse it
      if (typeof studentAddress === "string") {
        try {
          studentAddress = JSON.parse(studentAddress);
        } catch (e) {
          console.error("Invalid JSON string:", studentAddress);
          studentAddress = {};
        }
      }

      // Ensure studentAddress is an object
      if (typeof studentAddress !== "object" || studentAddress === null) {
        studentAddress = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          student_address: {
            ...studentAddress,
            [name]: value,
          },
        },
      };
    });
  };

  handleInputparent = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let studentFather = prevState.dataInput.student_father;

      // Check if studentFather is a string and parse it
      if (typeof studentFather === "string") {
        try {
          studentFather = JSON.parse(studentFather);
        } catch (e) {
          console.error("Invalid JSON string:", studentFather);
          studentFather = {};
        }
      }

      // Ensure studentFather is an object
      if (typeof studentFather !== "object" || studentFather === null) {
        studentFather = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          student_father: {
            ...studentFather,
            [name]: value,
          },
        },
      };
    });
  };

  handleInputmother = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let studentMother = prevState.dataInput.student_mother;

      // Check if studentMother is a string and parse it
      if (typeof studentMother === "string") {
        try {
          studentMother = JSON.parse(studentMother);
        } catch (e) {
          console.error("Invalid JSON string:", studentMother);
          studentMother = {};
        }
      }

      // Ensure studentMother is an object
      if (typeof studentMother !== "object" || studentMother === null) {
        studentMother = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          student_mother: {
            ...studentMother,
            [name]: value,
          },
        },
      };
    });
  };
  lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };
  handleChangeTime = (times) => {
    this.setState((prevState) => {
      let Times = prevState.dataInput.not_eats;

      // Check if studentMother is a string and parse it
      if (typeof Times === "string") {
        try {
          Times = JSON.parse(Times);
        } catch (e) {
          // console.error("Invalid JSON string:", studentMother);
          Times = {};
        }
      }

      // Ensure studentMother is an object
      if (typeof Times !== "object" || Times === null) {
        Times = {};
      }

      return {
        dataInput: {
          ...prevState.dataInput,
          not_eats: times,
        },
      };
    });

    // this.setState({
    //   dataInput: {
    //     ...this.state.dataInput,
    //     not_eats: times,
    //   },
    // });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    let data = {
      id: this.state.dataInput?.id,
      student_name: this.state.dataInput?.student_name,
      student_gender: this.state.dataInput?.student_gender,
      student_birthday: this.state.dataInput?.student_birthday,
      student_ethnic: this.state.dataInput?.student_ethnic,
      student_identifier: this.state.dataInput?.student_identifier,
      student_population: this.state.dataInput?.student_population,
      student_contact: this.state.dataInput?.student_contact,
      student_note: this.state.dataInput?.student_note,
      student_address: JSON.stringify({
        ...this.state.dataInput.student_address,
      }),
      student_father: JSON.stringify({
        ...this.state.dataInput.student_father,
      }),
      student_mother: JSON.stringify({
        ...this.state.dataInput.student_mother,
      }),
      student_status: this.state.dataInput?.student_status,
      start_at:
        new Date(this.state.dataInput?.start_at) / 1000 ||
        this.state.dataInput?.studyTimelines[0]?.start_at,
      end_at:
        new Date(this.state.dataInput?.end_at) / 1000 ||
        this.state.dataInput?.studyTimelines[0]?.end_at,
    };

    let dataNoEat = {
      id_student: this.state.dataInput?.id,
      id_ingredients:
        this.state.noIngredent?.list_ingredient?.map((item) => {
          return item.value;
        }) || [],
      notes: this.state.noIngredent?.note || "",
    };

    api
      .put(`/student-update`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        api
          .post(`/not-eat-ingredient-create`, dataNoEat, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            this.props.onSave();
            toast("Cập nhật thành công", { type: "success" });
          })
          .catch((err) => {
            toast("Cập nhật thất bại", { type: "error", autoClose: 1000 });
          });
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
      });
  };

  getStudent = async (id) => {
    await api
      .get(`/student-show?id=${id}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ dataInput: res.data.data });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  showNotEatTime = async (id) => {
    await api
      .get(`/not-eat-time-show?id=${id}`)
      .then((res) => {
        this.setState({
          notEatTime: res.data.data,
          showEditModalNotEat: true,
        });
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  destroyNotEatTime = async (id) => {
    swal({
      title: `Bạn chắc muốn xóa?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/not-eat-time-destroy?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.getStudent(this.state.dataInput.id);
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };

  destroyMedic = async (id) => {
    swal({
      title: `Bạn chắc muốn xóa nhắc nhỡ?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/medication-reminder-delete?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.getMedic(this.state.dataInput?.id);
          })
          .catch((err) => {
            toast(err.message, { type: "error" });
          });
      }
    });
  };

  destroyClassHasStudent = async (id) => {
    swal({
      title: `Bạn chắc muốn xóa?`,
      text: `**Lưu ý: Khi xóa sẽ ảnh đến dữ liệu "Nghỉ học" và "Cắt suất".`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/class-has-student-destroy?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.getStudent(this.state.dataInput.id);
          })
          .catch((error) => {
            toastError(error);
          });
      }
    });
  };

  destroyStudentMeal = async (id) => {
    swal({
      title: `Bạn chắc muốn xóa?`,
      text: `**Lưu ý: Khi xóa sẽ ảnh đến dữ liệu "Nghỉ học" và "Cắt suất".`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then(async (ok) => {
      if (ok) {
        await api
          .delete(`/student-meal-destroy?id=${id}`)
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            this.getStudent(this.state.dataInput.id);
          })
          .catch((error) => {
            toastError(error);
          });
      }
    });
  };

  dataClass() {
    if (this.state.listclass?.length > 0) {
      return this.state.listclass?.map((item, i) => {
        return (
          <option key={`class_1_${i}`} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    } else {
      return this.props?.listclass?.map((item, i) => {
        return (
          <option key={`class_1_${i}`} value={item.id}>
            {item.class_name}
          </option>
        );
      });
    }
  }

  formclose = () => {
    this.setState({ btnClick: false });
    this.setState({ show: false });
  };

  onSave = () => {
    this.setState({ btnClick: false });
    this.setState({ show: false });
    this.getlistclass();
    this.props.onSaveClass();
  };

  getOptionTimes = () => {
    var arr_ = [];
    this.state.listTimes?.map((item, index) =>
      item?.time_name_now != null
        ? arr_.push({ value: item.id, label: item.time_name_now })
        : null
    );
    const nodes = [
      {
        value: "0",
        label: "Không ăn cả ngày ",

        children: arr_,
      },
    ];

    return (
      <>
        <CheckboxTree
          nodes={nodes || []}
          showExpandAll
          checked={[...(this.state.dataInput?.notEatTimes[0]?.id_times || [])]}
          expanded={[0]}
          icons={{
            check: (
              <i
                className="fa-solid fa-square-check"
                style={{ color: "#005eff" }}
              ></i>
            ),
            uncheck: (
              <i
                className="fa-regular fa-square"
                style={{ color: "#005eff" }}
              ></i>
            ),
            halfCheck: (
              <i
                className="fa-regular fa-square "
                style={{ color: "#99beff" }}
              ></i>
            ),
            expandClose: "",
            expandOpen: "",
            parentClose: "",
            parentOpen: "",
            leaf: "",
          }}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="xl"
          backdrop="static"
          keyboard={false}
          id="addStudentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Chi tiết học sinh {this.state.dataInput?.student_name}
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Tabs
                variant="tabs"
                onSelect={(k) => this.setState({ tabkey: k })}
                defaultActiveKey={1}
                className="mb-3 d-flex"
                style={{
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  overflowY: "hidden",
                  flexWrap: "nowrap",
                  // height: "41px",
                }}
              >
                <Tab key={1} title="Học sinh" eventKey={1}>
                  <Row>
                    <Col>
                      <Form.Floating className="mb-3  mb-md-0">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_identifier
                          }
                          onChange={this.handleInput}
                          name="student_identifier"
                          id="student_identifier"
                          placeholder="Mã định danh Bộ GD&ĐT"
                        />
                        <Form.Label>
                          Mã định danh Bộ GD&ĐT<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_identifier}
                        </span>
                      </Form.Floating>
                    </Col>

                    <Col>
                      <Form.Floating className="mb-3 mb-md-0">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_population
                          }
                          onChange={this.handleInput}
                          name="student_population"
                          id="student_population"
                          placeholder="Mã định danh dân cư"
                        />
                        <Form.Label>
                          Mã định danh dân cư
                          <i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_population}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8}>
                      <br />
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          className="text-capitalize"
                          defaultValue={this.state.dataInput?.student_name}
                          onChange={this.handleInput}
                          name="student_name"
                          id="student_name"
                          placeholder="Tên học sinh"
                          required
                        />
                        <Form.Label>
                          Tên học sinh<i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_name}
                        </span>
                      </Form.Floating>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="text-end ">
                        <Link
                          className="fst-italic"
                          onClick={() =>
                            this.props.onChangeClass(this.props.data)
                          }
                        >
                          <i className="fa-solid fa-person-walking-luggage" />{" "}
                          Chuyển lớp
                        </Link>
                      </Form.Group>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.classHistories?.filter(
                              (item) =>
                                item.start_at <= new Date() / 1000 &&
                                (item.end_at >= new Date() / 1000 ||
                                  item.end_at == null)
                            )[0]?.class_name ?? null
                          }
                          disabled
                        />
                        <Form.Label>Lớp</Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4} xs={6}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={this.state.dataInput?.student_gender}
                          onChange={this.handleInput}
                          name="student_gender"
                          required
                        >
                          <option value="">--Chọn--</option>
                          <option value="1">Nam</option>
                          <option value="2">Nữ</option>
                        </Form.Select>
                        <Form.Label>
                          Giới tính
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_gender}
                        </span>
                      </Form.Floating>
                    </Col>

                    <Col md={4} xs={6}>
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          defaultValue={moment(
                            new Date(
                              this.state.dataInput?.student_birthday * 1000
                            )
                          ).format("YYYY-MM-DD")}
                          className="form-control"
                          required
                          name="student_birthday"
                          onChange={this.handleInput}
                        />
                        <Form.Label>
                          Ngày sinh
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_birthday}
                        </span>
                      </Form.Floating>
                    </Col>

                    <Col md={4} xs={6}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          value={this.state.dataInput?.student_ethnic}
                          onChange={this.handleInput}
                          name="student_ethnic"
                        >
                          <option value="">--Chọn--</option>
                          {this.state.listEthnic.length > 0
                            ? this.state.listEthnic.map(
                                (ethnic_, indexEthnic) => {
                                  return (
                                    <option
                                      key={`ethnic-key${indexEthnic}`}
                                      value={ethnic_.id}
                                    >
                                      {ethnic_.ethnic_name}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </Form.Select>
                        <Form.Label>Dân tộc</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_ethnic}
                        </span>
                      </Form.Floating>
                    </Col>

                    <Col md={4} xs={6}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={this.state.dataInput?.student_contact}
                          onChange={this.handleInput}
                          name="student_contact"
                          placeholder="SĐT liên hệ"
                        />
                        <Form.Label>
                          SĐT liên hệ<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_contact}
                        </span>
                      </Form.Floating>
                    </Col>

                    <Col md={8}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={this.state.dataInput?.student_note}
                          onChange={this.handleInput}
                          name="student_note"
                          placeholder="Ghi chú"
                        />
                        <Form.Label>
                          Ghi chú<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_note}
                        </span>
                      </Form.Floating>
                    </Col>

                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Select
                          defaultValue={this.state.dataInput?.student_status}
                          onChange={this.handleInput}
                          name="student_status"
                        >
                          <option value={1}>Đang học</option>
                          <option value={0}>Tạm nghỉ</option>
                        </Form.Select>
                        <Form.Label>
                          Trạng thái
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.student_status}
                        </span>
                      </Form.Floating>
                    </Col>

                    {/* {this.state.dataInput?.student_status == IS_ACTIVE.TRUE
                      ? */}
                    <Col
                      md={4}
                      xs={6}
                      className={`${
                        Number(this.state.dataInput?.student_status) ===
                        Number(IS_ACTIVE.FALSE)
                          ? "d-none"
                          : ""
                      }`}
                    >
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className={`form-control`}
                          name="start_at"
                          defaultValue={moment(
                            new Date(
                              this.state.dataInput?.studyTimelines[0]
                                ?.start_at * 1000
                            )
                          ).format("YYYY-MM-DD")}
                          onChange={this.handleInput}
                        />
                        <Form.Label>
                          Ngày nhập học<i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.start_at}
                        </span>
                      </Form.Floating>
                    </Col>
                    {/* : */}
                    <Col
                      md={4}
                      xs={6}
                      className={`${
                        Number(this.state.dataInput?.student_status) ===
                        Number(IS_ACTIVE.TRUE)
                          ? "d-none"
                          : ""
                      }`}
                    >
                      <Form.Floating className="mb-3">
                        <input
                          type="date"
                          className={`form-control`}
                          name="end_at"
                          defaultValue={
                            this.state.dataInput?.studyTimelines[0]?.end_at
                              ? moment(
                                  new Date(
                                    this.state.dataInput?.studyTimelines[0]
                                      ?.end_at * 1000
                                  )
                                ).format("YYYY-MM-DD")
                              : null
                          }
                          onChange={this.handleInput}
                          required={
                            Number(this.state.dataInput?.student_status) === 0
                          }
                          disabled={
                            Number(this.state.dataInput?.student_status) === 1
                          }
                        />
                        <Form.Label>
                          Ngày nghỉ học
                          <i className="text-danger">
                            {Number(this.state.dataInput?.student_status) === 0
                              ? "*"
                              : ""}
                          </i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.end_at}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>

                <Tab key={2} title="Phụ huynh" eventKey={2}>
                  <Row>
                    <Col md={6}>
                      <p style={{ fontWeight: "bold" }}>Thông tin cha</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          className="text-capitalize"
                          value={this.state.dataInput?.student_father?.name}
                          onChange={this.handleInputparent}
                          name="name"
                          id="name"
                          placeholder="Họ và tên"
                        />
                        <Form.Label>
                          Họ và tên<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_father.name`]}
                        </span>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_father?.job
                          }
                          onChange={this.handleInputparent}
                          name="job"
                          id="job"
                          placeholder="Nghề nghiệp"
                        />
                        <Form.Label>
                          Nghề nghiệp<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_father.job`]}
                        </span>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_father?.phone
                          }
                          onChange={this.handleInputparent}
                          name="phone"
                          id="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>
                          Số điện thoại<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_father.phone`]}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={6}>
                      <p style={{ fontWeight: "bold" }}>Thông tin mẹ</p>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          className="text-capitalize"
                          defaultValue={
                            this.state.dataInput?.student_mother?.name
                          }
                          onChange={this.handleInputmother}
                          name="name"
                          id="name"
                          placeholder="Họ và tên"
                        />
                        <Form.Label>
                          Họ và tên<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_mother?.name`]}
                        </span>
                      </Form.Floating>

                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_mother?.job
                          }
                          onChange={this.handleInputmother}
                          name="job"
                          id="job"
                          placeholder="Nghề nghiệp"
                        />

                        <Form.Label>
                          Nghề nghiệp<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err["student_mother.job"]}
                        </span>
                      </Form.Floating>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_mother?.phone
                          }
                          onChange={this.handleInputmother}
                          name="phone"
                          id="phone"
                          placeholder="Số điện thoại"
                        />
                        <Form.Label>
                          Số điện thoại<i className="text-danger"></i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err[`student_mother.phone`]}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <p style={{ fontWeight: "bold" }}>Địa chỉ gia đình</p>
                  <Row>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_address?.number
                          }
                          onChange={this.handleInputaddress}
                          name="number"
                          id="number"
                          placeholder="Số nhà"
                        />
                        <Form.Label>
                          Số nhà<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={2}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_address?.group
                          }
                          onChange={this.handleInputaddress}
                          name="group"
                          id="group"
                          placeholder="Tổ"
                        />
                        <Form.Label>
                          Tổ<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={3}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_address?.zone
                          }
                          onChange={this.handleInputaddress}
                          name="zone"
                          id="zone"
                          placeholder="Khu vực"
                        />
                        <Form.Label>
                          Khu vực<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_address?.street
                          }
                          onChange={this.handleInputaddress}
                          name="street"
                          id="street"
                          placeholder="Đường"
                        />
                        <Form.Label>
                          Đường<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_address?.ward
                          }
                          onChange={this.handleInputaddress}
                          name="ward"
                          id="ward"
                          placeholder="Phường / Xã"
                        />
                        <Form.Label>Phường / Xã</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_address?.district
                          }
                          onChange={this.handleInputaddress}
                          name="distric"
                          id="distric"
                          placeholder="Quận / Huyện"
                        />
                        <Form.Label>Quận / Huyện</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={
                            this.state.dataInput?.student_address?.city
                          }
                          onChange={this.handleInputaddress}
                          name="city"
                          id="city"
                          placeholder="Tỉnh / Thành phố"
                        />
                        <Form.Label>Tỉnh / Thành phố</Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>

                <Tab key={3} title="Nguyên liệu dị ứng" eventKey={3}>
                  <Select
                    className="mb-3"
                    options={this.props.ingredents}
                    placeholder={<div>Chọn nguyên liệu</div>}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: "50px",
                      }),
                    }}
                    value={this.state.noIngredent?.list_ingredient}
                    onChange={(choice) =>
                      this.setState((prevState) => ({
                        noIngredent: {
                          ...prevState.noIngredent,
                          list_ingredient: choice,
                        },
                      }))
                    }
                    isMulti
                  />

                  <Row>
                    <Col>
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          defaultValue={this.state.noIngredent?.note}
                          onChange={(e) =>
                            this.setState((prevState) => ({
                              noIngredent: {
                                ...prevState.noIngredent,
                                note: e.target.value,
                              },
                            }))
                          }
                          placeholder="Ghi chú"
                        />
                        <Form.Label>
                          Ghi chú<i className="text-danger"></i>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                  </Row>
                </Tab>

                <Tab key={4} title="Quá trình chuyển lớp" eventKey={4}>
                  <div className="text-end">
                    <Button
                      variant="primary"
                      size="sm"
                      className="mb-2 "
                      onClick={() => this.props.onChangeClass(this.props.data)}
                    >
                      <i className="fa-solid fa-person-walking-luggage" />{" "}
                      Chuyển lớp
                    </Button>
                  </div>
                  <div style={{ overflow: "auto" }}>
                    <Table bordered>
                      <thead>
                        <tr className="bg-light">
                          <th className="text-center">STT</th>
                          <th style={{ minWidth: "150px" }}>Lớp học</th>
                          <th style={{ minWidth: "100px" }}>Năm học</th>
                          <th className="text-end">Ngày vào lớp</th>
                          <th className="text-end">Ngày kết thúc</th>
                          <th className="text-end">Cập nhật</th>
                          <th style={{ minWidth: "100px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataInput?.classHistories?.map(
                          (item, index) => {
                            return (
                              <tr key={`history_${index}`}>
                                <td className="text-center">{index + 1}</td>
                                <td>{item.class_name}</td>
                                <td>{item.year_name}</td>
                                <td className="text-end">
                                  {dateFormatter(
                                    new Date(item.start_at * 1000)
                                  )}
                                </td>
                                <td className="text-end">
                                  {item.end_at ? (
                                    dateFormatter(new Date(item.end_at * 1000))
                                  ) : (
                                    <span className="text-success">
                                      Vô thời hạn
                                    </span>
                                  )}
                                </td>
                                <td className="text-end">
                                  {item.updated_at > 0
                                    ? formatTimestamp(item.updated_at)
                                    : ""}
                                </td>
                                <td className="text-center">
                                  <Button
                                    size="sm"
                                    variant="outline-warning"
                                    className="me-2"
                                    onClick={() => {
                                      this.setState({
                                        showClassHistoryModal: true,
                                        classHasStudent: item,
                                      });
                                    }}
                                  >
                                    <i className="fa-solid fa-pencil" />
                                  </Button>
                                  <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() =>
                                      this.destroyClassHasStudent(item.id)
                                    }
                                    title="Xóa"
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </Button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Tab>

                <Tab key={5} title="Lịch sử học tại trường" eventKey={5}>
                  <Table bordered>
                    <thead>
                      <tr className="bg-light">
                        <th className="text-center">STT</th>
                        <th className="text-end">Ngày nhập học</th>
                        <th className="text-end">Ngày kết thúc</th>
                        <th className="text-end">Cập nhật</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataInput?.studyTimelines?.map(
                        (item, index) => {
                          return (
                            <tr key={`timeline_${index}`}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-end">
                                {dateFormatter(new Date(item.start_at * 1000))}
                              </td>
                              <td className="text-end">
                                {item.end_at ? (
                                  dateFormatter(new Date(item.end_at * 1000))
                                ) : (
                                  <span className="text-success">
                                    Vô thời hạn
                                  </span>
                                )}
                              </td>
                              <td className="text-end">
                                {item.updated_at > 0
                                  ? formatTimestamp(item.updated_at)
                                  : ""}
                              </td>
                              <td className="text-center">
                                <Button
                                  size="sm"
                                  variant="outline-warning"
                                  className="me-2"
                                  onClick={() => {
                                    this.setState({
                                      showStudentMealModal: true,
                                      studentMeal: item,
                                    });
                                  }}
                                >
                                  <i className="fa-solid fa-pencil" />
                                </Button>
                                <Button
                                  size="sm"
                                  variant="outline-danger"
                                  onClick={() =>
                                    this.destroyStudentMeal(item.id)
                                  }
                                  title="Xóa"
                                >
                                  <i className="fa-solid fa-trash" />
                                </Button>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </Tab>

                <Tab key={6} title="Không ăn tại trường" eventKey={6}>
                  <Button
                    variant="success"
                    size="sm"
                    className="mb-2 float-end"
                    onClick={() => this.setState({ showAddModalNotEat: true })}
                  >
                    <i className="fa-solid fa-plus" /> Thêm
                  </Button>

                  <Table bordered>
                    <thead>
                      <tr className="bg-light">
                        <th className="text-center">STT</th>
                        <th className="text-end">Ngày bắt đầu</th>
                        <th className="text-end">Ngày kết thúc</th>
                        <th className="text-end">Tiền giảm</th>
                        <th className="text-end">Cập nhật</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataInput?.notEatTimes?.length > 0 ? (
                        this.state.dataInput?.notEatTimes?.map(
                          (item, index) => {
                            return (
                              <tr key={`notEatTime_${index}`}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-end">
                                  {dateFormatter(
                                    new Date(item.start_at * 1000)
                                  )}
                                </td>
                                <td className="text-end">
                                  {item.end_at ? (
                                    dateFormatter(new Date(item.end_at * 1000))
                                  ) : (
                                    <span className="text-success">
                                      Vô thời hạn
                                    </span>
                                  )}
                                </td>
                                <td className="text-end">
                                  <CurrencyFormat
                                    value={parseInt(item.price_return)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </td>
                                <td className="text-end">
                                  {formatTimestamp(
                                    item.updated_at,
                                    "dd/mm/yyyy hh:mm:ss"
                                  )}
                                </td>
                                <td className="text-center">
                                  <Button
                                    variant="outline-warning"
                                    size="sm"
                                    onClick={() => this.showNotEatTime(item.id)}
                                    title="Sửa"
                                    className="me-2"
                                  >
                                    <i className="fa-solid fa-pencil" />
                                  </Button>

                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() =>
                                      this.destroyNotEatTime(item.id)
                                    }
                                    title="Xóa"
                                  >
                                    <i className="fa-solid fa-trash" />
                                  </Button>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-muted">
                            Không có dữ liệu...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Tab>

                <Tab key={7} title="Sổ nhận thuốc" eventKey={7}>
                  <Button
                    variant="success"
                    size="sm"
                    className="mb-2 float-end"
                    onClick={() => this.setState({ showAddModalMedic: true })}
                    hidden
                    title="Thêm "
                  >
                    <i className="fa-solid fa-plus" /> Thêm
                  </Button>

                  <div className="text-end mb-2">
                    <strong>
                      Tháng {this.state.startDate?.getMonth() + 1} /{" "}
                      {this.state.startDate?.getFullYear()}&nbsp;
                    </strong>

                    <input
                      type="month"
                      name="month"
                      style={{
                        border: "none",
                        width: 39,
                        backgroundColor: "rgb(16,108,252)",
                        borderRadius: 5,
                      }}
                      className="me-2"
                      onChange={(e) => {
                        if (e.target.value) {
                          const [year, month] = e.target.value.split("-");
                          this.setState(
                            {
                              startDate: new Date(year, month - 1),
                              endDate: this.lastDayy(
                                new Date(year, month - 1).getFullYear(),
                                Number(new Date(year, month - 1).getMonth()) + 1
                              ),
                            },
                            () => this.getMedic(this.state.dataInput?.id)
                          );
                        }
                      }}
                    />
                  </div>

                  <Table bordered>
                    <thead>
                      <tr className="bg-light">
                        <th className="text-center">STT</th>
                        <th className="text-center">Ngày</th>
                        <th className="text-center">Thời gian</th>
                        <th className="text-center">Ghi chú</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state?.medication?.map((item, indexMedication) => {
                        return (
                          <>
                            <tr
                              key={`notEatTime_1_${indexMedication}`}
                              className=" align-middle"
                            >
                              <td
                                rowSpan={item?.description?.times?.length}
                                className="text-center"
                              >
                                {indexMedication + 1}
                              </td>
                              <td
                                rowSpan={item?.description?.times?.length}
                                className="text-center"
                              >
                                {dateFormatter(new Date(item.date_time * 1000))}
                              </td>
                              <td className="text-center">
                                {getTime(item?.description?.times[0]?.time)}
                              </td>
                              <td rowSpan={item.description?.times?.length}>
                                {item?.description?.note}
                              </td>
                              <td
                                className="text-center d-none"
                                rowSpan={item?.description?.times?.length}
                              >
                                <Button
                                  variant="outline-warning"
                                  size="sm"
                                  onClick={() => {
                                    this.setState({
                                      showMedicModal: true,
                                      itemMedic: item,
                                    });
                                  }}
                                  title="Sửa"
                                  className="me-2"
                                >
                                  <i className="fa-solid fa-pencil" />
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => this.destroyMedic(item.id)}
                                  title="Xóa"
                                >
                                  <i className="fa-solid fa-trash" />
                                </Button>
                              </td>
                            </tr>
                            {item.description?.times
                              ?.slice(1, item.description?.times?.length)
                              ?.map((time, indexTime) => (
                                <tr
                                  key={`key-time-${indexMedication}-${indexTime}`}
                                >
                                  <td className="text-center">
                                    {getTime(time.time)}
                                  </td>
                                </tr>
                              ))}
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </Tab>
                {/* <Tab key={8} title="Thành tích" eventKey={8}>
                  <Achievement dataStudent={this.props.data} />
                </Tab>
                <Tab key={9} title="Sổ bé ngoan" eventKey={9}>
                  <Reward dataStudent={this.props.data} />
                </Tab> */}
              </Tabs>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddstudent"
                onClick={() => {
                  this.setState({
                    tabkey: 1,
                    abledForm: false,
                    abledFormPause: true,
                  });
                  this.props.onHide();
                }}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              {new Check().permission(["20"]) ? (
                <Button
                  size="sm"
                  variant="success"
                  type="submit"
                  disabled={this.state.btnClick}
                >
                  <i className="fa-solid fa-check"></i> Cập nhật
                </Button>
              ) : (
                <></>
              )}
            </Modal.Footer>
          </Form>
        </Modal>

        {this.state.showAddModalNotEat ? (
          <NotEatTimeAddModal
            show={this.state.showAddModalNotEat}
            onHide={() => this.setState({ showAddModalNotEat: false })}
            onSave={() => {
              this.setState({ showAddModalNotEat: false });
              this.getStudent(this.state.dataInput.id);
            }}
            student={{
              ...this.state.dataInput,
              id_times: [],
              price_return: "",
              start_at: "",
            }}
          />
        ) : (
          <></>
        )}

        {this.state.showEditModalNotEat ? (
          <NotEatTimeEditModal
            show={this.state.showEditModalNotEat}
            onHide={() => this.setState({ showEditModalNotEat: false })}
            onSave={() => {
              this.setState({ showEditModalNotEat: false });
              this.getStudent(this.state.dataInput.id);
            }}
            notEatTime={{
              ...this.state.notEatTime,
              student_name: this.state?.dataInput.student_name,
            }}
          />
        ) : (
          <></>
        )}

        {this.state.showAddModalMedic ? (
          <MedicModal
            show={this.state.showAddModalMedic}
            id_student={this.state.dataInput?.id}
            onHide={() => this.setState({ showAddModalMedic: false })}
            submit={() => {
              this.setState({ showAddModalMedic: false });
              this.getMedic(this.state.dataInput?.id);
              // this.getevaluation();
            }}
          />
        ) : null}

        {this.state.showMedicModal ? (
          <>
            <MedicEditModal
              show={this.state.showMedicModal}
              onHide={() => this.setState({ showMedicModal: false })}
              modal={{
                title: "Chi tiết thuốc",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              type={"TM"}
              datarole={this.state.itemMedic}
              submit={() => {
                this.setState({ showMedicModal: false });
                this.getMedic(this.state.dataInput?.id);
                // this.getevaluation();
              }}
            />
          </>
        ) : null}

        {this.state.showClassHistoryModal ? (
          <ClassHistoryEditModal
            show={this.state.showClassHistoryModal}
            onHide={() => this.setState({ showClassHistoryModal: false })}
            onSave={() => {
              this.setState({
                showClassHistoryModal: false,
                classHasStudent: {},
              });
              this.getStudent(this.state.dataInput.id);
            }}
            classHasStudent={this.state.classHasStudent}
          />
        ) : (
          <></>
        )}

        {this.state.showStudentMealModal ? (
          <StudentMealEditModal
            show={this.state.showStudentMealModal}
            onHide={() => this.setState({ showStudentMealModal: false })}
            onSave={() => {
              this.setState({
                showStudentMealModal: false,
                studentMeal: {},
              });

              this.getStudent(this.state.dataInput.id);
            }}
            studentMeal={this.state.studentMeal}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default StudentEdit;
